import React from 'react';
import { distanceFormatting } from '@firsttable/functions';
import spaces from '@firsttable/web-theme/styledSpaces';
import { CuisinesConnection } from '@firsttable/types';
import { SpaceProps } from 'styled-system';
import { Icon, Box, Divider, Text, Link } from '../../../atoms';

type LocationPropTypes = {
  cuisines?: CuisinesConnection | null;
  distance?: number;
  city?: any;
  suburb?: any;
  street?: any;
  region?: any;
  NavLinkComponent?: any;
  showFullAddress?: boolean;
};

const Location = ({
  cuisines,
  distance,
  city,
  street,
  region,
  suburb,
  NavLinkComponent,
  showFullAddress = false,
  ...props
}: LocationPropTypes & SpaceProps) => {
  let address = suburb?.menuTitle ?? region?.menuTitle;
  if (showFullAddress && street) {
    address = `${street}, ${city}`;
  }
  return (
    <Box {...spaces(props)}>
      <Icon name="s-map-marker" color="grayscale.600" mr="7px" mt="-3px" />
      {address || (
        <Link to={suburb?.slug ?? region.slug} NavComponent={NavLinkComponent}>
          {suburb?.menuTitle ?? region.menuTitle}
        </Link>
      )}
      {distance ? (
        <Text as="span" color="grayscale.600">
          {' '}
          {distanceFormatting(distance)}
        </Text>
      ) : null}
      {cuisines &&
        cuisines.edges.filter(({ node: { label } }) => label).length > 0 && (
          <>
            <Divider
              width="1px"
              height="19px"
              display="inline-block"
              top="6px"
              mx="15px"
            />
            {cuisines.edges.map(({ node: { label } }) => label).join(', ')}
          </>
        )}
    </Box>
  );
};

export default Location;
