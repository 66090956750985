import React from 'react';
import get from 'lodash/get';
import ReviewCard from './ReviewCard';

const Review = ({ ...rest }) => {
  const replies = get(rest, 'review.replies') || [];
  return (
    <>
      <ReviewCard
        {...rest}
        name={rest.review.name}
        comment={rest.review.comment}
        date={rest.review.date}
      />
      {replies.length > 0 && (
        <>
          {replies.map((reply) => (
            <ReviewCard
              ml={20}
              {...rest}
              name={reply.name}
              userType={reply.userType}
              comment={reply.comment}
              key={reply.id}
            />
          ))}
        </>
      )}
    </>
  );
};

Review.propTypes = {};
Review.defaultProps = {};

export default Review;
