import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import media from 'styled-media-query';
import Box from '../../atoms/Box';

const StyledAvailabilityOriginal = styled(Box)`
  cursor: ${(props) => (!props.available ? 'default' : 'pointer')};
  user-select: none;
  transition: 0.2s ease;
  border: 1px solid transparent;
  color: ${(props) => !props.available && themeGet('colors.grayscale.600')};
  align-self: flex-start;
  white-space: nowrap;
  padding: 3px 0 5px;

  ${({ available }) =>
    available
      ? css`
          &:hover {
            border-color: ${themeGet('colors.grayscale.300')};
          }
        `
      : null};
  ${media.greaterThan('520px')`
    padding: 3px 8px 5px;
  `};
  ${({ selected }) =>
    selected
      ? css`
          border-color: ${themeGet('colors.gold.800')};
        `
      : null}
`;

const StyledAvailability = styled(Box)`
  cursor: ${(props) => (!props.available ? 'default' : 'pointer')};
  user-select: none;
  transition: 0.15s ease-out;
  border-left: ${(props) =>
    props.position === 0 ? 'none' : '1px solid white'};
  color: ${(props) => !props.available && themeGet('colors.grayscale.600')};
  align-self: flex-start;
  white-space: nowrap;
  padding: 3px 0 5px;
  min-width: 53px;
  transition: all 0.2s ease;

  ${({ available }) =>
    available
      ? css`
          &:hover {
            color: ${themeGet('colors.white')};
            background: ${themeGet('colors.buttons.normal.hover')};
            > div {
              border-color: white;
              color: white;
              opacity: 1;
            }
          }
          color: ${themeGet('colors.white')};
          background: ${themeGet('colors.brand.blue')};
        `
      : css`
          background: ${themeGet('colors.grayscale.300')};
          color: ${themeGet('colors.grayscale.500')};
        `};
  ${media.greaterThan('350px')`
    padding: 3px 0 5px;
  `};
  ${media.greaterThan('400px')`
    padding: 3px 5px 5px;
  `};
  ${media.greaterThan('520px')`
    padding: 6px 8px 11px;
  `};
  ${({ selected }) =>
    selected
      ? css`
          border-color: ${themeGet('colors.gold.800')};
        `
      : null}
  ${({ processing }) =>
    processing
      ? css`
          background: ${themeGet('colors.buttons.normal.hover')};
        `
      : null}
`;

const WeekDay = styled(Box)`
  padding: 0 0 4px;
  margin-bottom: 4px;
  transition: all 0.5s ease;
  ${({ available }) =>
    available
      ? css`
          border-bottom: 1px solid ${themeGet('colors.gold.400')};
          color: ${themeGet('colors.gold.400')};
          opacity: 0.6;
        `
      : css`
          border-bottom: 1px solid ${themeGet('colors.grayscale.400')};
          color: ${themeGet('colors.grayscale.600')};
        `};

  @media screen and (min-width: 550px) {
    padding: 0 15px 7px;
    margin-bottom: 7px;
  }
  @media screen and (min-width: 630px) {
    padding: 0 0 6px;
  }
`;

const CountdownArrow = styled.div`
  display: block;
  width: 0;
  height: 0;
  margin: 15px auto 5px;
  border-style: solid;
  border-width: 0 11px 12px 11px;
  border-color: transparent transparent ${themeGet('colors.error')} transparent;
`;

const Wrapper = styled(Box)`
  @media (max-width: 519px) {
    // ↓↓ 12px + ((1vw - 320/100) * (100 * (15-12) / (520-320))) ↓↓
    font-size: calc(12px + ((1vw - 3.2px) * 1.5));
  }
  @media (min-width: 520px) {
    font-size: 15px;
  }
  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export {
  StyledAvailability,
  StyledAvailabilityOriginal,
  WeekDay,
  CountdownArrow,
  Wrapper,
};
