import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { awsImage } from '@firsttable/functions';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Box, Flex, Link, BodyTitle, Text,
} from '../../../../atoms';
import Rating from '../../Rating';

const imgWidth = 85;
const imgHeight = 85;

const Item = ({ restaurant, NavLinkComponent }) => (
  <Link to={restaurant.slug} NavComponent={NavLinkComponent}>
    <Flex alignItems="center">
      <Box
        mr="10px"
        width={imgWidth}
        height={imgHeight}
        style={{ flexShrink: 0 }}
        className="bg-pulse-animation"
      >
        <LazyLoadImage
          srcSet={`${awsImage(restaurant.gallery, imgWidth, imgHeight)} 1x, ${awsImage(restaurant.gallery, imgWidth * 2, imgHeight * 2)} 2x`}
          src={awsImage(restaurant.gallery, imgWidth, imgHeight)}
          alt={restaurant.title}
          className="img-fluid"
          effect="opacity"
        />
      </Box>
      <Box flex={1}>
        <BodyTitle size="xs" mb="0.2em">
          {restaurant.title}
        </BodyTitle>
        <Flex flexWrap="wrap">
          <Rating rating={restaurant.rating} m="-3px 0 0" />
          <Box ml="7px">
            <Box color="grayscale.600" fontSize="s">
              {restaurant.approvedReviewsCount}
              {' '}
              Reviews
            </Box>
          </Box>
        </Flex>
        {restaurant.cuisine && <Text mb={0} fontSize="xs" lineHeight="xs">{restaurant.cuisine}</Text>}
      </Box>
    </Flex>
  </Link>
);

Item.propTypes = {
  restaurant: PropTypes.object,
  NavLinkComponent: PropTypes.any.isRequired,
};

Item.defaultProps = {
  restaurant: {},
};

export default Item;
