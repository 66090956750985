import React from 'react';
import PropTypes from 'prop-types';
import Pin from './Pin';

const RestaurantPin = ({ onClick, color }) => (
  <Pin
    onClick={onClick}
    style={{
      cursor: 'pointer',
      fill: color,
      stroke: 'none',
    }}
  />
);

RestaurantPin.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
};
RestaurantPin.defaultProps = {
  onClick: () => {},
  color: '#FF685D',
};

export default RestaurantPin;
