const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: `${process.env.GATSBY_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.GATSBY_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: '949599212204',
  appId: '1:949599212204:web:6f3110ef4195f109',
  measurementId: 'G-4X89L7GP2J',
};

let firebaseInstance;
const getFirebase = (firebase) => {
  if (firebaseInstance) {
    return firebaseInstance;
  }
  firebase.initializeApp(config);
  firebaseInstance = firebase;

  return firebase;
};
/**
 * sets up firebase remote config, checks if there is an indexedDB (firebase private browsing doesn't and RC wont work) and return latest config
 * @param event
 * @param firebase
 * @param settings
 * @returns {Promise<T>}
 */
export const fireBaseRemoteConfig = async (event, firebase, settings = {}) => {
  const fireBaseValue = await new Promise((resolve, reject) => {
    const db =
      window.indexedDB ||
      window.mozIndexedDB ||
      window.webkitIndexedDB ||
      window.msIndexedDB;

    const firebaseDB = db.open('firebaseRemoteDBTest');
    firebaseDB.onerror = function () {
      reject();
    };
    firebaseDB.onsuccess = async function () {
      const remoteConfig = firebase.remoteConfig();

      remoteConfig.settings = {
        minimumFetchIntervalMillis: 43200000, // = 12 hours use a low number in development e.g. 3600000,
        fetchTimeoutMillis: 20000, // default 60000 ms
        ...settings,
      };
      // https://firebase.google.com/docs/reference/android/com/google/firebase/remoteconfig/FirebaseRemoteConfig
      await remoteConfig.fetchAndActivate().catch((e) => console.log('e', e));
      let value;
      switch (event.type) {
        case 'boolean':
          value = remoteConfig.getBoolean(event.name);
          break;
        case 'string':
          value = remoteConfig.getString(event.name);
          break;
        case 'number':
          value = remoteConfig.getNumber(event.name);
          break;
        case 'object':
        case 'array':
          value = remoteConfig.getObject(event.name);
          break;
        default:
          value = remoteConfig.getValue(event);
          break;
      }
      resolve(value);
    };
  }).catch(() => null);

  return fireBaseValue;
};

export default getFirebase;
