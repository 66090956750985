import React from 'react';
import gql from 'graphql-tag';
import AlertBannerComponent from '@firsttable/web-components/molecules/AlertBanner';
import { useQuery } from '@apollo/client';
import { Box, Link } from '@firsttable/web-components/atoms';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import NavLinkComponent from '../../atoms/NavLink';

export const GET_ALERT_STATUS = gql`
  query getAlertStatus($siteId: Int!) {
    SiteConfig(siteId: $siteId) {
      alertMessage
      alertType
    }
    MemberAlert {
      hasMarkedAsSpam
      hasBouncedEmail
    }
  }
`;

const SuccessBanner = ({ children }) => (
  <AlertBannerComponent
    message={
      <Box
        width={[600, 'auto', 'auto']}
        textAlign={['center', 'left', 'center']}
        py={['6px', '0']}
      >
        {children}
      </Box>
    }
    type="success"
  />
);

const AlertBanner = () => {
  const { pathname } = useLocation();
  const paths = pathname.split('/').filter((p) => p);

  const staticData = useStaticQuery(graphql`
    query siteTimeZone {
      siteConfig {
        defaultTimeZone
      }
    }
  `);
  const timezone = staticData.siteConfig.defaultTimeZone;
  let date = new Date()
    // Note: Must use en-US as it's the only one available in Node
    .toLocaleString('en-US', {
      timeZone: timezone || 'UTC',
    });
  // Note: this is US formatted date
  date = date.substring(0, date.indexOf(','));
  const siteId = +process.env.GATSBY_SITE_ID;
  const { data } = useQuery(GET_ALERT_STATUS, {
    pollInterval: 60000, // every minute
    ssr: false,
    fetchPolicy: 'network-only',
    variables: {
      siteId,
    },
  });

  if (data) {
    const { SiteConfig, MemberAlert } = data;

    if (MemberAlert.hasMarkedAsSpam || MemberAlert.hasBouncedEmail) {
      const message = (
        <>
          Hi there. We tried emailing you, but we had some troubles as our email
          was bounced or marked as spam. Please&nbsp;{' '}
          <Link NavComponent={NavLinkComponent} to="/profile/emails" underline>
            click here
          </Link>{' '}
          &nbsp;if you’d like us to retry
        </>
      );
      return <AlertBannerComponent message={message} type="danger" />;
    }

    if (SiteConfig.alertMessage) {
      return (
        <AlertBannerComponent
          message={SiteConfig.alertMessage}
          type={SiteConfig.alertType}
        />
      );
    }
  }

  const isFridayFrenzy = date === '10/1/2021';
  const inPath = (path) => paths && paths.includes(path);

  const nz = 1;
  const aus = 2;
  const canada = 4;
  const uk = 8;
  const indonesia = 12;

  // Friday frenzy
  if ([nz, aus, uk, canada, indonesia].includes(siteId) && isFridayFrenzy) {
    return (
      <SuccessBanner>
        {
          siteId === nz && `Half price breakfast and lunch bookings, today only! Celebrate school holidays with a $5 First Table 🥳`
        }
        {
          siteId === uk && `Half price bookings, today only! Book your next First Table for just £2.50 🥳`
        }
        {
          siteId === canada && `Half price bookings, today only! Book your next First Table for just $5 🥳`
        }
        {
          siteId === indonesia && `It’s our third birthday! Celebrate with half price bookings, today only 🥳`
        }️
      </SuccessBanner>
    );
  }

  // Book to win - Queenstown
  /*
  if ([nz].includes(siteId) && date < '8/31/2021' && !isFridayFrenzy) {
    return (
      <SuccessBanner>
        🏔{' '}
        <Link
          NavComponent={NavLinkComponent}
          to="/competitions/home-of-first-table-giveaway/"
          underline
        >
          #BookToWIN
        </Link>{' '}
        a trip (or staycation) to Queenstown, worth $4,000 🏔
      </SuccessBanner>
    );
  }
   */

  // Sunshine coast - Book to win
  if (inPath('sunshine-coast') && date <= '8/14/2021' && !isFridayFrenzy) {
    return (
      <SuccessBanner>
        💃 #BookToWin before August 15 to WIN 1 of 3 $20 booking credits 🕺
      </SuccessBanner>
    );
  }

  /*
  // London - Parks Edge
  if (
    [uk].includes(siteId) &&
    inPath('london') &&
    date <= '8/4/2021' &&
    !isFridayFrenzy
  ) {
    return (
      <SuccessBanner>
        ⚡️ Win: dinner for two at{' '}
        <Link
          NavComponent={NavLinkComponent}
          to="/competitions/parks-edge-bar-and-kitchen-giveaway/"
          underline
        >
          Park's Edge Bar
        </Link>{' '}
        ⚡️
      </SuccessBanner>
    );
  }
  */

  // Australia Covid
  if ([aus].includes(siteId)) {
    return (
      <SuccessBanner>
        <Link NavComponent={NavLinkComponent} to="/covid-19/" underline>
          Learn more
        </Link>{' '}
        about our Covid-19 updates
      </SuccessBanner>
    );
  }

  // UK Covid
  /*
  if ([uk].includes(siteId)) {
    return (
      <SuccessBanner>
        Covid-19:{' '}
        <Link NavComponent={NavLinkComponent} to="/covid-19/" underline>
          Learn more
        </Link>{' '}
        about our reopening plans
      </SuccessBanner>
    );
  }
   */

  // NZ Covid
  // if ([nz].includes(siteId) && date <= '9/7/2021') { // US formatted date
  //   return (
  //     <SuccessBanner>
  //       🎁
  //       {' '}
  //       <Link NavComponent={NavLinkComponent} to="/gift-vouchers/purchase/" underline>20% off gift vouchers sale ends at midnight</Link>
  //       {' '}
  //       🎁
  //     </SuccessBanner>
  //   );
  // }

  return null;
};

SuccessBanner.propTypes = {
  children: PropTypes.node,
};
AlertBanner.propTypes = {};
AlertBanner.defaultProps = {};

export default AlertBanner;
