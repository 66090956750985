import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { search } from '../apollo/cache';
/**
 * Docs: https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/#storing-local-state-in-reactive-variables
 * @param ComponentToWrap
 */
const withCitySearchFilters = (ComponentToWrap: any) => {
  const WithAuthContext = (props: any) => {
    const restaurantSearch = useReactiveVar(search);
    return (
      <ComponentToWrap
        restaurantSearch={restaurantSearch}
        updateSearchFiltersQuery={search}
        {...props}
      />
    );
  };
  WithAuthContext.displayName = 'withCitySearchFilters';

  return WithAuthContext;
};

export default withCitySearchFilters;
