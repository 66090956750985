import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Field } from 'formik';
import { useQuery } from '@apollo/client';
import { numericToWord, range } from '@firsttable/functions';
import gql from 'graphql-tag';
import moment from 'moment';
import FormField from '../../../molecules/FormField/FormField';
import { BodyTitle, Text } from '../../../atoms';
import { getLeaveTime } from '../../../molecules/BookingTable/BookingTable';

const GET_MAX_ALLOWED_DINERS = gql`
  query getMaxAllowedDiners($reference: String!) {
    bookingMaxAllowedDiners: Booking(reference: $reference) {
      id
      maxAllowedDiners
    }
  }
`;

const ExtraDinersField = ({ booking, disabled }) => {
  // handle loading extra diners
  let extraDinerOptions = [{ value: 0, label: 'Loading extra diners..' }];

  const { loading: loadingMaxDiners, data } = useQuery(GET_MAX_ALLOWED_DINERS, {
    variables: {
      reference: booking.reference,
    },
    fetchPolicy: 'network-only',
    skip: booking.restaurant.additionalPeopleAllowed === 0,
  });

  const leaveTime = booking?.availability?.leaveTimes
    ? JSON.parse(booking.availability.leaveTimes)
    : '';

  const checkTime = (times, table) => {
    // check if array
    if (Array.isArray(times) && !times.length) {
      return '';
    }
    // check if string
    if (!times) {
      return '';
    }

    const time = times[table];
    if (!time) return '';

    return `(Leave time: ${moment(time, 'HH:mm:ss').format('h:mm A')})`;
  };

  if (!loadingMaxDiners && data) {
    const {
      bookingMaxAllowedDiners: { maxAllowedDiners },
    } = data;
    const dinerCount = maxAllowedDiners - 4;

    if (dinerCount <= 0) {
      return null;
    }

    extraDinerOptions = range(1, dinerCount).map((i) => ({
      value: i,
      label: `Add ${numericToWord(i)} extra ${
        i > 1 ? 'people' : 'person'
      } ${checkTime(leaveTime, i + 4)}`,
    }));
  }

  if (booking.restaurant.additionalPeopleAllowed <= 0) {
    return null;
  }

  return (
    <>
      <BodyTitle
        size="s"
        m="20px 0"
        color={disabled ? `grayscale.600` : 'black'}
      >
        This restaurant allows additional full-price paying people to be added
        to your First Table{' '}
        {disabled && (
          <Text fontSize={16} fontWeight="normal" as="span">
            (select four people to enable)
          </Text>
        )}
      </BodyTitle>
      <Field
        component={FormField}
        type="select"
        placeholder={
          !isMobile
            ? 'Select additional full-price paying people if you have any'
            : 'Additional full-price people'
        }
        name="additionalPeople"
        mb={['15px', '30px']}
        isClearable
        options={extraDinerOptions}
        isDisabled={disabled}
      />
    </>
  );
};

ExtraDinersField.propTypes = {
  booking: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
};
ExtraDinersField.defaultProps = {};

export default ExtraDinersField;
