import React from 'react';
import PropTypes from 'prop-types';
import Pin from './Pin';
// import { ReactComponent as IconMapMarker } from './map-point.svg';

const style = {
  cursor: 'pointer',
  fill: '#265682',
  stroke: 'none',
};

const UserPin = ({ onClick }) => (
  <Pin onClick={onClick} style={style} />
);

UserPin.propTypes = {
  onClick: PropTypes.func,
};
UserPin.defaultProps = {
  onClick: () => {},
};

export default UserPin;
