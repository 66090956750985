import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Container, Title, Text, Button, BodyTitle } from '../../atoms';
// @ts-ignore
import cutlery01 from './cutlery-01.svg';
// @ts-ignore
import cutlery02 from './cutlery-02.svg';

const Wrapper = styled(Box)`
  background: url('${cutlery01}') no-repeat calc(50% + 680px) calc(100% + 70px),
    url('${cutlery02}') no-repeat calc(50% - 725px) -90px,
    linear-gradient(180deg, #f5f9fc, #fff);
`;

const OurStory = ({ NavButtonComponent }: { NavButtonComponent: any }) => (
  <Wrapper py={[50, null, 120]}>
    <Container>
      <Box maxWidth={[null, 780]} mx="auto" textAlign="center">
        <BodyTitle
          fontWeight={400}
          color="grayscale.600"
          size="s"
          letterSpacing={0}
        >
          Our Story
        </BodyTitle>
        <Title mb={[20, null, 'l']}>
          A win-win for foodies and restaurants alike
        </Title>
        <Text fontSize={[null, null, 'xl']} mb={['l', 'xl']}>
          First Table was created by Mat Weir, a tech entrepreneur who wanted to
          solve the problem of slow starts to evenings at restaurants. From
          humble beginnings as a one-man-band in Queenstown, our Kiwi founder
          embarked on a journey to build a creative solution that would be a
          win-win experience for foodies and restaurants alike.
        </Text>
        <Box maxWidth={180} mx="auto">
          <Button NavComponent={NavButtonComponent} to="/our-story/" wide>
            Read more
          </Button>
        </Box>
      </Box>
    </Container>
  </Wrapper>
);

OurStory.propTypes = {
  NavButtonComponent: PropTypes.any.isRequired,
};

export default OurStory;
