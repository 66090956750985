import React from 'react';
import {
  Box,
  Col,
  Flex,
  Icon,
} from '@firsttable/web-components/atoms';
import PropTypes from 'prop-types';
import bgImg from '../Modal/img/bg01.jpg';

const InfoBlock = ({ children }) => (
  <Col
    height={[null, '100vh']}
    position="sticky"
    top={0}
    display="flex"
    width={[1, 1 / 2]}
    background={`url(${bgImg}) no-repeat 50% 50% / cover`}
    p={['l', 0]}
  >
    <Box
      m="auto"
      width={[null, '80%', '68%']}
      maxWidth={650}
      color="white"
    >
      <Flex alignItems="center" mb="30px">
        <Icon
          width="1em"
          height="1.2em"
          viewBox="0 0 40 48"
          fontSize="34px"
          name="cutlery"
          color="white"
        />
        <Box
          ml="30px"
          height="1px"
          bg="white"
          width="174px"
        />
      </Flex>
      {children}
    </Box>
  </Col>
);

export default InfoBlock;

InfoBlock.propTypes = {
  children: PropTypes.any,
};
