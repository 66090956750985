import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { compact } from 'lodash';
import Box from '../../atoms/Box';
import Flex from '../../atoms/Flex';
import Text from '../../atoms/Text';
import Icon from '../../atoms/Icon';
import InputCheckbox from '../../molecules/InputCheckbox';
import FilterExtender from '../../atoms/Extender';
import Button from '../../atoms/Button';

const StyledButton = styled(Button)`
  position: relative;
  top: -1px;
  font-size: ${themeGet('fontSizes.xxs')}px;
  color: ${themeGet('colors.grayscale.600')};
  text-transform: uppercase;
  padding: 0;
  border: 0;
`;

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      isVisible: true,
    };
  }

  toggleVisibilityItems = () => {
    this.setState((state) => ({
      isExpanded: !state.isExpanded,
    }));
  };

  toggleVisibilityList = () => {
    this.setState((state) => ({
      isVisible: !state.isVisible,
    }));
  };

  render() {
    const {
      name,
      filters,
      handleFilters,
      selectedValues,
      clearFilters,
    } = this.props;
    const { isExpanded, isVisible } = this.state;
    const compactedFilters = compact(filters);
    const maxItems = 10;
    const iconName = `s-arrow-${isVisible ? 'up' : 'down'}`;

    return (
      <Box mb="20px">
        <Flex mb="18px">
          <Flex alignItems="baseline" flex={1} mr="10px">
            <Text fontSize="l" mr="17px" mb={0}>
              <strong>{name}</strong>
            </Text>
            <StyledButton
              type="button"
              kind="white-transparent"
              onClick={clearFilters}
            >
              clear
            </StyledButton>
          </Flex>
          <Box role="button" onClick={this.toggleVisibilityList}>
            <Icon name={iconName} color="grayscale.600" m="-2px 2px 0 0" />
          </Box>
        </Flex>
        <Box hidden={!isVisible && 'hidden'}>
          <Box
            maxHeight={[null, null, 'calc(100vh - 250px)']}
            overflow={[null, null, 'auto']}
            mr={[-8, -8, null]}
            pr={[8, 8, null]}
          >
            {compactedFilters.map((filter, i) => (
              <Box
                mb="9px"
                key={filter.id}
                hidden={i >= maxItems && !isExpanded && 'hidden'}
              >
                <InputCheckbox
                  key={filter.id}
                  label={filter.label}
                  type="checkbox"
                  onChange={handleFilters}
                  position="right"
                  selected={selectedValues.includes(filter.id)}
                  value={`${filter.id}`}
                  name={`${filter.label}`}
                />
              </Box>
            ))}
          </Box>
          {compactedFilters.length > maxItems && (
            <FilterExtender
              isExpanded={isExpanded}
              onClick={this.toggleVisibilityItems}
            />
          )}
        </Box>
      </Box>
    );
  }
}

Filter.propTypes = {
  name: PropTypes.string,
  filters: PropTypes.array,
  selectedValues: PropTypes.array,
  handleFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};
Filter.defaultProps = {
  name: '',
  filters: [],
  selectedValues: [],
};

export default Filter;
