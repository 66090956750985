import React from 'react';
import globalHook from 'use-global-hook';

/**
 * add/removes filters to existing filters
 * @param currentFilters
 * @param filter
 * @returns {*[]}
 */
const processSearchFilters = (currentFilters, filter) => {
  const newFilters = [...currentFilters];
  if (filter) {
    const index = newFilters.indexOf(filter);
    // add/remove filters
    if (index !== -1) {
      newFilters.splice(index, 1);
    } else {
      newFilters.push(filter);
    }
  }
  return newFilters;
};

const initialState = {
  cuisine: [],
  filters: [],
  showMap: false,
};

const actions = {
  setCuisineFilters: (store, e) => {
    const {
      state: { cuisine },
    } = store;
    const filterValue = +e.target.value;
    const newFilters = processSearchFilters(cuisine, filterValue);
    store.setState({ cuisine: newFilters });
  },
  setFilters: (store, e) => {
    const {
      state: { filters },
    } = store;
    const filterValue = e.target.value;
    const newFilters = processSearchFilters(filters, filterValue);
    store.setState({ filters: newFilters });
  },
  handleMapView: (store) => {
    const {
      state: { showMap },
    } = store;
    store.setState({ showMap: !showMap });
  },
  clearCuisineFilters: (store) => {
    store.setState({ cuisine: [] });
  },
  clearFilters: (store) => {
    store.setState({ filters: [] });
  },
};

const useGlobal = globalHook(React, initialState, actions);

const withSearchFilters = (ComponentToWrap) => (props) => {
  const [searchFilterState, searchFilterActions] = useGlobal();
  return (
    <ComponentToWrap
      searchFilterState={searchFilterState}
      searchFilterActions={searchFilterActions}
      {...props}
    />
  );
};

export default withSearchFilters;
