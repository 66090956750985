import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { switchProp } from 'styled-tools';
import * as theme from '@firsttable/web-theme';
import Box from '../../../atoms/Box';

const StarSource = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
`;

const Star = styled.svg`
  display: block;
  ${switchProp('size', {
    s: css`
      height: 13px;
    `,
    m: css`
      height: 17px;
    `,
    l: css`
      height: 19px;
    `,
  })}
`;

const rounderNumber = (num: number) => Math.round(num * 2) / 2;

const Rating: any = ({
  rating,
  size,
  ...props
}: {
  rating: number;
  size?: any;
}) => {
  const starSize = 32;
  const stars = 5;
  const spaceBetween = 5;
  const viewBox = `0 0 ${
    starSize * (stars - 1) + spaceBetween * (stars - 1) + starSize
  } ${starSize}`;
  const colors = {
    gray: theme.colors.grayscale[400],
    brand: theme.colors.brand.red,
    half: 'url(#halfStar)',
  };

  const roundedStarRating = rounderNumber(rating);

  const getFilledColor = (star: number) => {
    if (roundedStarRating >= star) {
      return colors.brand;
    }
    if (roundedStarRating + 0.5 === star) {
      return colors.half;
    }
    return colors.gray;
  };

  return (
    <Box {...props}>
      <StarSource xmlns="http://www.w3.org/2000/svg">
        <defs>
          <g id="icon-star">
            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118l11.547-1.2L16.026,0.6L20.388,10.918z" />
          </g>
          <linearGradient id="halfStar">
            <stop offset="0" stopColor={colors.brand} />
            <stop offset="50%" stopColor={colors.brand} />
            <stop offset="50%" stopColor={colors.gray} />
            <stop offset="100%" stopColor={colors.gray} />
          </linearGradient>
        </defs>
      </StarSource>
      {/* @ts-ignore */}
      <Star viewBox={viewBox} size={size}>
        {/* @ts-ignore */}
        {Array.apply(0, Array(stars)).map((x, i) => (
          <use
            key={i}
            xlinkHref="#icon-star"
            x={starSize * i + spaceBetween * i}
            y="0"
            fill={getFilledColor(i + 1)}
          />
        ))}
      </Star>
    </Box>
  );
};

Rating.propTypes = {
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alignSelf: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
};
Rating.defaultProps = {
  rating: 1,
  alignSelf: 'center',
  size: 'm',
};

export default Rating;
