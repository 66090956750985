import React from 'react';
import PropTypes from 'prop-types';
import Flex from '../../atoms/Flex';
import Box from '../../atoms/Box';

const PriceRating = (props) => {
  const { rating } = props;
  if (rating === 0) {
    return null;
  }
  return (
    <Flex {...props} fontFamily="headings" fontSize="l">
      {Array.apply(0, Array(5)).map((x, i) => <Box key={i} color={rating > i ? 'black' : 'grayscale.400'} mr="2px">$</Box>)}
    </Flex>
  );
};

PriceRating.propTypes = {
  rating: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  ...Box.propTypes,
};
PriceRating.defaultProps = {
  rating: 0,
};

export default PriceRating;
