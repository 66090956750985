import React from 'react';
import PropTypes from 'prop-types';
import { graphql as apolloGraphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { Field, withFormik } from 'formik';
import { navigate } from 'gatsby';
import * as Yup from 'yup';
import { CREATE_USER } from '@firsttable/graphql-queries';
import {
  errorMessage,
  isBrowser,
  scrollTo,
  signUpEvent,
} from '@firsttable/functions';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import {
  BodyTitle,
  Box,
  Button,
  Divider,
  Alert as FormMessage,
} from '@firsttable/web-components/atoms';
import Cookies from 'js-cookie';
import FormLayout from '@firsttable/web-components/organisms/Forms/Form';
import withLocation from '../../../hocs/location';
import FacebookLoginButton from '../Auth/FacebookLoginButton';
import RegionField from './Fields/RegionField';
import { fbq, dataLayer } from '../../../helpers/events';
import ContactPermissionField from './Fields/ContactPermissionField';
import TermsAndConditionsField from './Fields/TermsAndConditionsField';
import withBackUrl from '../../../hocs/backUrl';
import withModal from '../../../hocs/withModal';

const SignUpForm = ({
  handleSubmit,
  isSubmitting,
  status,
  values,
  children,
  isModal,
  ...props
}) => (
  <FormLayout
    handleSubmit={handleSubmit}
    display={null}
    {...props}
    data-testid="signup__form"
    isModal={isModal}
  >
    {children}
    <FacebookLoginButton signUpData={values} />
    <Divider m="35px 0 28px" />
    {status && status.message && (
      <FormMessage mb="32px" message={status.message} type={status.type} />
    )}
    <Field
      component={FormField}
      mb={['15px', '20px']}
      id="firstName"
      name="firstName"
      label="First Name"
      placeholder="Enter your first name"
      size="l"
      type="text"
    />
    <Field
      component={FormField}
      mb={['15px', '20px']}
      id="surname"
      name="surname"
      label="Surname"
      placeholder="Enter your surname"
      size="l"
      type="text"
    />
    <Field
      component={FormField}
      mb={['15px', '20px']}
      id="email"
      name="email"
      label="Your email"
      placeholder="Enter your email"
      size="l"
      type="email"
    />
    <Field
      component={FormField}
      mb={['15px', '20px']}
      id="password"
      name="password"
      placeholder="Enter a password"
      label="Password"
      size="l"
      type="password"
    />
    <Field
      component={FormField}
      mb={['15px', '20px']}
      id="confirm"
      name="confirm"
      placeholder="Confirm your password"
      label="Confirm password"
      size="l"
      type="password"
    />
    <RegionField placeholder="Select a region" mb={['15px', '20px']} />
    <Field
      component={FormField}
      mb={['15px', '20px']}
      id="PromoCode"
      name="promoCode"
      placeholder="Enter your promo code if you have one"
      label="Promo Code"
      size="l"
      type="text"
    />
    <BodyTitle size="xs">Terms and conditions</BodyTitle>
    <TermsAndConditionsField
      name="termsAndPrivacy"
      id="Terms"
      mt={25}
      mb={20}
    />
    <BodyTitle size="xs">Contact permission</BodyTitle>
    <ContactPermissionField name="contactPermission" id="ContactPermission" />
    <Field
      component={FormField}
      type="recaptcha"
      name="recaptcha"
      id="SignUpRecaptcha"
    />
    <Box mt={['20px', '30px']} textAlign="center">
      <Button wide size="l" kind="cta" type="submit" isLoading={isSubmitting}>
        Sign up
      </Button>
    </Box>
  </FormLayout>
);

SignUpForm.propTypes = {
  values: PropTypes.shape(),
  children: PropTypes.node,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isModal: PropTypes.bool,
  status: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default compose(
  withModal,
  withLocation,
  withBackUrl,
  apolloGraphql(CREATE_USER, { name: 'createMember' }),
  withFormik({
    mapPropsToValues: ({
      search: { City, code },
      inviteCode,
      GDPRCountry,
      signUpData,
      cityId,
    }) => {
      const codeCookie = Cookies.get('code');
      let promoCode = code || codeCookie;
      if (inviteCode) {
        promoCode = inviteCode;
      }
      return {
        email: '',
        firstName: '',
        surname: '',
        password: '',
        confirm: '',
        promoCode,
        cityId: +City || cityId,
        termsAndPrivacy: false,
        contactPermission: GDPRCountry ? '' : '1',
        recaptcha: '',
        ...signUpData,
      };
    },
    validationSchema: () =>
      Yup.object().shape({
        firstName: Yup.string().required('Required'),
        surname: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        cityId: Yup.string().required('Required'),
        password: Yup.string()
          .required('Password is required')
          .min(8, 'Must be at least 8 characters in length'),
        confirm: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Password confirm is required'),
        termsAndPrivacy: Yup.boolean()
          .oneOf([true], 'Must Accept Terms and Conditions')
          .required(),
        contactPermission: Yup.string().required(
          'Contact permission is required',
        ),
        recaptcha:
          isBrowser && !window.Cypress
            ? Yup.string().required("Please confirm you're not a bot")
            : null,
      }),
    handleSubmit: async (
      values,
      {
        setSubmitting,
        setStatus,
        props: {
          createMember,
          setUser,
          backUrl,
          search,
          hideModal,
          siteConfig,
          isModal,
        },
      },
    ) => {
      const { backUrl: queryBackUrl } = search;
      try {
        const { data, error } = await createMember({
          variables: {
            ...values,
            siteId: +process.env.GATSBY_SITE_ID,
            contactPermission: values.contactPermission === '1',
          },
        });
        if (error) {
          setStatus({ message: errorMessage(error), type: 'danger' });
          scrollTo('form-alert', -20);
          return;
        }
        const member = data.createMember;
        // log in user
        setUser({
          ...member,
          isLoggedIn: true,
        });
        fbq('track', 'CompleteRegistration', {
          value: 0,
          currency: siteConfig.currency,
        });

        const region = member.city.menuTitle;

        signUpEvent({
          loginMethod: 'email',
          member,
          permissions: values.contactPermission ? 'TRUE' : 'FALSE',
          region,
        });

        const cityURL = member.city.slug;
        let returnUrl = cityURL || '/auth/complete/';

        if (cityURL) {
          returnUrl += '?confirmed=1';
        }
        // remove any promo codes
        if (Cookies.get('code')) {
          Cookies.remove('code');
        }
        if (!queryBackUrl && (!backUrl || backUrl === '/')) {
          Cookies.set('userSignedUp', true);
        } else {
          returnUrl = queryBackUrl || backUrl;
        }
        hideModal();
        await navigate(returnUrl, { replace: true });
      } catch (e) {
        setSubmitting(false);
        setStatus({ message: errorMessage(e), type: 'danger' });
        scrollTo('form-alert', -20);
        if (isModal) {
          document.getElementById('ReactModal').scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      }
    },
  }),
)(SignUpForm);
