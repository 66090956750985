import React from 'react';
import PropTypes from 'prop-types';
import WebTheme from '@firsttable/web-theme/layout';
import { Box } from '@firsttable/web-components/atoms';
import CookieConsent from '@firsttable/web-components/molecules/CookieConsent';
import SEO from '../components/seo';
import Modal from '../components/organisms/Modal';
import AlertBanner from '../components/organisms/AlertBanner';
import '../css/swiper-core-5.1.0.scss';
import NavLink from '../components/atoms/NavLink';

const layout = ({ children, footer }) => (
  <WebTheme footer={footer}>
    <SEO />
    <AlertBanner />
    <Box flex="1 0 auto">{children}</Box>
    {footer}
    <Modal />
    {parseInt(process.env.GATSBY_SITE_ID, 10) === 8 && (
      <CookieConsent NavLinkComponent={NavLink} />
    )}
  </WebTheme>
);

layout.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
};
layout.defaultProps = {
  footer: null,
};

export default layout;
