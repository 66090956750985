import React from 'react';
import PropTypes from 'prop-types';
import { themeGet } from '@firsttable/web-theme';
import { Restaurant as RestaurantType } from '@firsttable/types';
import { range } from '@firsttable/functions';
import {
  Box,
  Flex,
  Link,
  BodyTitle,
  LabelNew,
  Divider,
  Switch,
  SwitchBorder,
} from '../../../atoms';
import Rating from '../Rating';
import Location from '../Location';
import Rank from '../../../molecules/Rank';
import Cover from './Cover';
import { Wrapper } from '../../../molecules/Availability';

interface AvailabilityComponentType {
  availability: any;
  restaurant: RestaurantType;
  availabilitySession?: string;
  selectedDate?: string;
  restaurantStatuses?: any;
}
interface AvailabilityToDisplayType {
  AvailabilityComponent: any;
}

type SessionStateType = {
  currentSession: string;
  setSession: (s: string) => void;
};

const AvailabilityToDisplay = ({
  AvailabilityComponent,
  availability,
  restaurant,
  availabilitySession,
  selectedDate,
  restaurantStatuses,
  ...props
}: AvailabilityToDisplayType & AvailabilityComponentType) => {
  let availabilityStatus = { ...restaurant };
  if (restaurantStatuses.length > 0) {
    const [restaurantStatus] = restaurantStatuses.filter(
      (item: RestaurantType) => item.id === restaurant.foreignId
    );
    if (restaurantStatus) {
      availabilityStatus = restaurantStatus;
    }
  }

  if (
    !process.env.GATSBY_SHOW_LOCKDOWN_AVAILABILITY &&
    availabilityStatus.status === 'On Hold' &&
    availabilityStatus.onHoldText &&
    availabilityStatus.onHoldText.length > 0
  ) {
    return (
      <Flex
        background={themeGet('colors.grayscale.300')}
        color={themeGet('colors.grayscale.600')}
        alignItems="center"
        justifyContent="center"
        height={[65, 95]}
      >
        <BodyTitle size="xs" m={0} p={0}>
          {availabilityStatus.onHoldText.charAt(0).toUpperCase() +
            availabilityStatus.onHoldText.slice(1)}
        </BodyTitle>
      </Flex>
    );
  }
  return (
    <AvailabilityComponent
      availability={availability}
      restaurant={restaurant}
      availabilitySession={availabilitySession}
      selectedDate={selectedDate}
      testid={`availability__list--${restaurant.foreignId}`}
      {...props}
    />
  );
};
AvailabilityToDisplay.propTypes = {
  AvailabilityComponent: PropTypes.any.isRequired,
  availability: PropTypes.shape({
    session: PropTypes.string,
  }).isRequired,
  restaurant: PropTypes.shape({
    foreignId: PropTypes.number,
  }).isRequired,
  availabilitySession: PropTypes.string.isRequired,
  selectedDate: PropTypes.string.isRequired,
  restaurantStatuses: PropTypes.array,
};

AvailabilityToDisplay.defaultProps = {
  restaurantStatuses: [],
};

interface RestaurantDetailsPropsType {
  restaurant: RestaurantType;
  AmexBox?: any;
  NavLinkComponent: any;
  showLastTableLayout?: boolean;
  showFullAddress?: boolean;
}

type RestaurantItemPropsType = RestaurantDetailsPropsType & {
  isLoading: boolean;
  mb?: number | string;
  city?: any;
  AvailabilitySessionBtn: any;
  showLastTableLayout?: boolean;
};

const RestaurantReviewsAndLocation = ({
  restaurant,
  AmexBox,
  NavLinkComponent,
  currentSession,
  setSession,
  showLastTableLayout,
}: RestaurantDetailsPropsType & SessionStateType) => (
  <Flex mb={12} flexWrap="wrap" minHeight={[0, 0, 32]}>
    <Flex mr="auto" alignItems="center">
      <Rating rating={restaurant.rating ?? 5} />
      <Box m="0 0 0 13px" mr="15px" alignSelf="center">
        <Link
          color="grayscale.600"
          to={`${restaurant.slug}#reviews`}
          NavComponent={NavLinkComponent}
        >
          {restaurant.approvedReviewsCount} Reviews
        </Link>
      </Box>
      {!!(
        restaurant.additionalPeopleAllowed &&
        restaurant.additionalPeopleAllowed > 0
      ) && (
        <>
          <Divider
            width="1px"
            height="19px"
            display="inline-block"
            mr="15px"
            alignSelf="center"
          />
          <Box
            color="grayscale.600"
            alignSelf="center"
            mr="15px"
            title="Four+more: you can additional full-price paying diners"
            style={{ cursor: 'default' }}
          >
            4+
          </Box>
        </>
      )}
      {restaurant.acceptsAmex && AmexBox && (
        <>
          <Divider
            width="1px"
            height="19px"
            display="inline-block"
            mr="15px"
            alignSelf="center"
          />
          <AmexBox alignSelf="center" mr="15px" />
        </>
      )}
      {restaurant.bookingPrice && restaurant.siteId === 12 && (
        <>
          <Divider width="1px" height="19px" display="inline-block" mr="15px" />
          <Box color="grayscale.600" m="3px 0 0 0" mr="15px" alignSelf="center">
            Price: {restaurant.bookingPrice}
          </Box>
        </>
      )}
    </Flex>
    {showLastTableLayout && (
      <SwitchBorder fontSize={['xxs', 'xs']} alignSelf="center" top={[0, 0, 2]}>
        <Switch
          active={currentSession === 'dinner'}
          orientation="left"
          onClick={() => {
            setSession('dinner');
          }}
        >
          First Table
        </Switch>
        <Switch
          active={currentSession === 'dinner2'}
          orientation="right"
          onClick={() => {
            setSession('dinner2');
          }}
        >
          Last Table
        </Switch>
      </SwitchBorder>
    )}
  </Flex>
);

RestaurantReviewsAndLocation.propTypes = {};
RestaurantReviewsAndLocation.defaultProps = {};

const RestaurantDetails = ({
  restaurant,
  NavLinkComponent,
  showLastTableLayout,
  showFullAddress,
  ...props
}: RestaurantDetailsPropsType & SessionStateType) => (
  <>
    <Location
      {...restaurant}
      NavLinkComponent={NavLinkComponent}
      showFullAddress={showFullAddress}
    />
    <RestaurantReviewsAndLocation
      restaurant={restaurant}
      NavLinkComponent={NavLinkComponent}
      showLastTableLayout={showLastTableLayout}
      {...props}
    />
  </>
);

RestaurantDetails.propTypes = {};
RestaurantDetails.defaultProps = {};

const Item = ({
  restaurant,
  isLoading,
  AmexBox,
  mb,
  city,
  NavLinkComponent,
  currentSession,
  setSession,
  showLastTableLayout,
  showFullAddress = false,
  ...props
}: RestaurantItemPropsType & AvailabilityComponentType & SessionStateType) => (
  <Box
    display={['block', 'flex']}
    mb={mb}
    data-testid="restaurant-item"
    data-score={`discover: ${restaurant.discover} score: ${restaurant.score} region: ${restaurant.regionRanking} city: ${restaurant.cityRanking}`}
  >
    <Box
      mr={[0, 'l']}
      mb={['m', 0]}
      maxWidth={['100%', 200]}
      style={{ flexShrink: 0 }}
      alignSelf="flex-start"
    >
      <Box height={[null, 200]} width={[null, 200]} mx={['-16px', 0]}>
        <Link to={restaurant.slug} NavComponent={NavLinkComponent}>
          <Cover gallery={restaurant.gallery} title={restaurant.title} />
        </Link>
      </Box>
    </Box>
    <Box flex={1} width={[null, '1%']} fontSize={['15px', '16px']}>
      <Flex mt="-6px" mb="5px">
        <Rank
          rank={
            city.parentId === 0
              ? restaurant.regionRanking
              : restaurant.cityRanking
          }
          top="3px"
          mr="5px"
        />
        <BodyTitle size="xl" mb="0" lineHeight={['1', '1.3']}>
          <Box display="inline-block" mr="s">
            <Link to={restaurant.slug} NavComponent={NavLinkComponent} mr={15}>
              {restaurant.title}
            </Link>
            {restaurant.isNew && <LabelNew />}
          </Box>
          {restaurant.featuredText && (
            <LabelNew>{restaurant.featuredText}</LabelNew>
          )}
        </BodyTitle>
      </Flex>
      <RestaurantDetails
        restaurant={restaurant}
        NavLinkComponent={NavLinkComponent}
        AmexBox={AmexBox}
        currentSession={currentSession}
        setSession={setSession}
        showLastTableLayout={showLastTableLayout}
        showFullAddress={showFullAddress}
      />
      <Wrapper m={['0 -16px', '0', '0']}>
        {isLoading ? (
          <Flex
            className="pulse-animation"
            justifyContent="space-between"
            height={[63, 90, 95]}
          >
            {range(0, 7).map((k: any) => (
              <Box
                bg="grayscale.300"
                height="100%"
                mr={1}
                flexBasis={0}
                flexGrow={1}
                key={k}
              />
            ))}
          </Flex>
        ) : (
          <AvailabilityToDisplay restaurant={restaurant} {...props} />
        )}
      </Wrapper>
    </Box>
  </Box>
);

Item.propTypes = {
  selectedDate: PropTypes.string,
  availability: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isLoading: PropTypes.bool,
  AvailabilityComponent: PropTypes.any.isRequired,
  AmexBox: PropTypes.any,
  restaurant: PropTypes.object,
  availabilitySession: PropTypes.string,
  mb: PropTypes.number,
  city: PropTypes.object,
  NavLinkComponent: PropTypes.any.isRequired,
  showLastTableLayout: PropTypes.bool,
};

Item.defaultProps = {
  selectedDate: '',
  availability: {},
  isLoading: true,
  restaurant: {},
  availabilitySession: 'dinner',
  mb: 30,
  city: {},
  AmexBox: null,
  showLastTableLayout: false,
};

export default Item;
