import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import media from 'styled-media-query';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import { countFormat, roundToClosest } from '@firsttable/functions';
import Box from '../../atoms/Box';
import Flex from '../../atoms/Flex';
import Col from '../../atoms/Col';
import Row from '../../atoms/Row';
import Container from '../../atoms/Container';
import Link from '../../atoms/Link';
import Text from '../../atoms/Text';
import Title from '../../atoms/Title/Title';
import Button from '../../atoms/Button/Button';
import img01 from './Group-3.svg';
import Icon from '../../atoms/Icon';

const StyledFooter = styled(Box)`
  flex-shrink: 0;
  background: ${themeGet('colors.footer.bg')} url('${img01}') no-repeat
    calc(50% - 660px) 66px;
`;
const Nav = styled(Flex)`
  list-style: none;
  display: inline-flex;
  padding: 0;
  flex-wrap: wrap;
  li {
    padding: 7px 13px 0;
  }
  color: ${themeGet('colors.grayscale.400')};
  font-size: ${themeGet('fontSizes.s')}px;
  margin: 0 0 23px;
  ${media.lessThan(bp.s)`
    align-items: center;
    justify-content: center;
    li {
      text-align: center;
      padding: 5px 7px;
    }
    padding-top: 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.07);
  `};
`;
const Socials = styled(Flex)`
  .icon {
    circle {
      fill: ${themeGet('colors.footer.btn')};
      transition: 0.1s ease;
    }

    &:hover {
      circle {
        fill: ${themeGet('colors.footer.btnHover')};
      }
    }
  }
`;

const Footer = ({
  siteConfig,
  footerLinks,
  isMinorSite,
  NavLinkComponent,
  NavButtonComponent,
}) => (
  <StyledFooter pb="33px" pt={['50px', '160px']}>
    <Container>
      <Row mb={['40px', '120px']} textAlign="center">
        <Col width={[1 / 3]} display={['none', 'block']}>
          <Title color="white" size="h5" as="h2" m="0 0 14px">
            Diners
          </Title>
          <Text
            maxWidth="258px"
            m="auto"
            fontSize="l"
            color="grayscale.600"
            mb="22px"
          >
            Join more than{' '}
            {countFormat(
              roundToClosest(Math.max(500000, siteConfig.dinerCount), 100000)
            )}{' '}
            diners who already ❤ First Table.
          </Text>
          <Button
            NavComponent={NavButtonComponent}
            to="/frequently-asked-questions/"
            size="s"
            kind="white-transparent"
          >
            Learn more
          </Button>
        </Col>
        <Col
          width={[1 / 3]}
          display={['none', 'block']}
          line
          lineColor="rgba(255, 255, 255, 0.07)"
        >
          <Title color="white" size="h5" as="h2" m="0 0 14px">
            Restaurateurs
          </Title>
          <Text
            maxWidth="271px"
            m="auto"
            fontSize="l"
            color="grayscale.600"
            mb="22px"
          >
            Join more than{' '}
            {countFormat(roundToClosest(siteConfig.restaurantCount, 100))}{' '}
            restaurants who already ❤ First Table.
          </Text>
          <Button
            NavComponent={NavButtonComponent}
            to="/restaurants/"
            kind="white-transparent"
            size="s"
          >
            Learn more
          </Button>
        </Col>
        <Col
          width={[1, 1 / 3]}
          line
          lineColor="rgba(255, 255, 255, 0.07)"
          hideLineOnMobile
        >
          <Title color="white" size="h5" as="h2" m="0 0 14px">
            Follow us
          </Title>
          <Text
            maxWidth={[null, '278px']}
            m="auto"
            fontSize={['s', 'l']}
            color="grayscale.600"
            mb="22px"
          >
            Share the love by following and tagging us on social media.
          </Text>
          <Socials display="inline-flex">
            <Box m="0 6px">
              <Link href={siteConfig.facebookURL} aria-label="facebook">
                <Icon className="icon" name="l-facebook" />
              </Link>
            </Box>
            <Box m="0 6px">
              <Link href={siteConfig.instagramURL} aria-label="instagram">
                <Icon className="icon" name="l-instagram" />
              </Link>
            </Box>
          </Socials>
        </Col>
      </Row>
      <Row>
        <Col textAlign="center">
          <Nav as="ul">
            {footerLinks.edges.map(({ node }) => {
              // don't show pages with no FE template
              if (
                node.frontendTemplate !== undefined &&
                node.frontendTemplate !== 'None' &&
                !node.showOnFrontend
              ) {
                return null;
              }
              let FooterLink;
              if (
                (node.slug === '/restaurateurs/introduction/' ||
                  node.slug === '/restaurateurs/partners/') &&
                +process.env.GATSBY_SITE_ID !== 1
              ) {
                return null;
              }
              if (node.slug === '/restaurateurs/introduction/') {
                FooterLink = (
                  <Link
                    NavComponent={NavLinkComponent}
                    to="/restaurateurs/partners/"
                  >
                    Partnerships
                  </Link>
                );
              } else if (
                node.className === 'RedirectorPage' &&
                node.slug.includes('http')
              ) {
                FooterLink = <Link href={node.slug}>{node.menuTitle}</Link>;
              } else {
                FooterLink = (
                  <Link NavComponent={NavLinkComponent} to={node.slug}>
                    {node.menuTitle}
                  </Link>
                );
              }
              return <li key={node.id}>{FooterLink}</li>;
            })}
            <li>
              <Link NavComponent={NavLinkComponent} to="/our-story/">
                Our Story
              </Link>
            </li>
            {!isMinorSite && (
              <li>
                <Link NavComponent={NavLinkComponent} to="/gift-vouchers/">
                  Gift Vouchers
                </Link>
              </li>
            )}
            <li>
              <Link NavComponent={NavLinkComponent} to="/restaurants/">
                Restaurateurs
              </Link>
            </li>
          </Nav>
          <Text
            style={{ color: '#49647a' }}
            fontSize="s"
            mb="0"
            textAlign="center"
          >
            © First Table 2014 - {new Date().getFullYear()}
          </Text>
        </Col>
      </Row>
    </Container>
  </StyledFooter>
);

Footer.propTypes = {
  siteConfig: PropTypes.shape(),
  footerLinks: PropTypes.shape(),
  isMinorSite: PropTypes.bool,
  NavLinkComponent: PropTypes.any.isRequired,
  NavButtonComponent: PropTypes.any.isRequired,
};
Footer.defaultProps = {
  siteConfig: {
    facebookURL: '',
    instagramURL: '',
  },
  footerLinks: {
    edges: [],
  },
  isMinorSite: false,
};

export default Footer;
