import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import SignInForm from '../Forms/SignInForm';
import withLocation from '../../../hocs/location';
import AuthLayout from './AuthLayout';

const SignInLayout = (props) => {
  const { search, isModal } = props;
  const alert = useAlert();
  useEffect(() => {
    if (search.redirected === 'login') {
      alert.error('Please log in again');
    }
  }, []);

  return (
    <AuthLayout signIn isModal={isModal}>
      <SignInForm {...props} />
    </AuthLayout>
  );
};

SignInLayout.propTypes = {
  search: PropTypes.shape(),
  isModal: PropTypes.bool,
};

export default withLocation(SignInLayout);
