import React from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import { flowRight } from 'lodash';
import * as Yup from 'yup';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import { errorMessage } from '@firsttable/functions';
import Form from '@firsttable/web-components/molecules/Form';
import {
  Text,
  Box,
  Button,
  Divider,
  Alert as FormMessage,
  Link,
} from '@firsttable/web-components/atoms';
import { navigate } from 'gatsby';
import FacebookLoginButton from '../Auth/FacebookLoginButton';
import withLocation from '../../../hocs/location';
import { withAuth, withRedirectPath } from '../../../hocs';
import NavLinkComponent from '../../atoms/NavLink';

const SignInForm = ({ handleSubmit, isSubmitting, status, values, search }) => {
  const { backUrl } = search;
  const signUpUrl = `/auth/sign-up/${backUrl ? `?backUrl=${backUrl}` : ''}`;

  return (
    <Form onSubmit={handleSubmit} display={null} data-testid="login__form">
      <Text textAlign="center" mb="l">
        Don&rsquo;t have a free account?{' '}
        <Link
          NavComponent={NavLinkComponent}
          to={signUpUrl}
          color="brand.red"
          underline
        >
          Sign up
        </Link>
        .
      </Text>
      <FacebookLoginButton signUpData={values} />
      <Divider m="35px 0 28px" />
      {status && status.message && (
        <FormMessage mb="32px" message={status.message} type={status.type} />
      )}

      <Field
        component={FormField}
        mb={['15px', '20px']}
        id="email"
        name="email"
        label="Your email"
        size="l"
        type="email"
        data-testid="login__form__email"
      />
      <Field
        component={FormField}
        mb={['15px', '20px']}
        id="password"
        name="password"
        label="Password"
        size="l"
        type="password"
        data-testid="login__form__password"
      />
      <Box mt={['20px', '30px']} mb="20px">
        <Button
          size="l"
          wide
          kind="cta"
          type="submit"
          data-testid="login__form__submit"
          isLoading={isSubmitting}
        >
          Login
        </Button>
      </Box>
      <div>
        <Link // eslint-disable-line
          NavComponent={NavLinkComponent}
          to="/auth/forgot-password/"
          color="brand.red"
          underline
        >
          Forgot password?
        </Link>
      </div>
    </Form>
  );
};

SignInForm.propTypes = {
  values: PropTypes.shape(),
  search: PropTypes.shape(),
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  status: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

SignInForm.defaultProps = {
  isSubmitting: false,
};

export default flowRight(
  withLocation,
  withAuth,
  withRedirectPath,
  withFormik({
    mapPropsToValues: () => ({ email: '', password: '' }),
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().required('Required'),
      }),
    handleSubmit: async (
      values,
      {
        setSubmitting,
        setStatus,
        props: {
          handleEmailLogin,
          handlePostLogin,
          location: { pathname },
        },
      },
    ) => {
      setStatus(false);
      try {
        const { isLoggedIn } = await handleEmailLogin(
          values.email,
          values.password,
        );
        handlePostLogin({ isLoggedIn }, { setStatus, setSubmitting }).then(
          (d) => {
            if (d && !d.redirected && pathname.includes('auth')) {
              navigate('/');
            }
          },
        );
      } catch (e) {
        setStatus({
          message: errorMessage(e, 'There was an error signing in'),
          type: 'danger',
        });
        setSubmitting(false);
      }
    },
  }),
)(SignInForm);
