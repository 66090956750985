import styled from 'styled-components';
import Box from '../../../atoms/Box';

const LazyLoadImageWrapper = styled(Box)`
  position: relative;

  .lazyload-img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::after {
    content: '';
    display: block;
    animation: bgPulse 1s ease-out infinite;

    @media (max-width: ${({ max }) => max}px) {
      padding-bottom: 61.84896%;
    }

    @media (min-width: ${({ min }) => min}px) {
      padding-bottom: 100%;
    }
  }
`;

export { LazyLoadImageWrapper as default };
