/**
 * Node side
 */

export default function googleTagManager() {
  /* returns noOp because no server side implementation exists */
  return {
    name: 'google-tag-manager',
  };
}
