import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import NoSSR from 'react-no-ssr';
import media from 'styled-media-query';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import { Box, Flex, Col, Container, Icon, Row, Title } from '../../../atoms';
import Review from '../../Review';
// import Reviews from '../index';

const Arrow = styled(Box)`
  outline: 0;
`;

const mobileSpace = 20;
const space = 50;

const SwiperWrapper = styled(Box)`
  .swiper-wrapper {
    display: flex;
    box-sizing: border-box;
    padding: 8px ${mobileSpace}px 0;
  }
  ${media.greaterThan(bp.s)`
    .swiper-wrapper {
      padding: 0 ${space}px 90px;
    }
    .swiper-slide {
      width: 590px;
    }
  `};
`;

const SwiperComponent = ({ reviews }) => {
  const [swiper, updateSwiper] = useState(null);

  const params = {
    slidesPerView: 'auto',
    spaceBetween: space,
    cssMode: true,
    mousewheel: true,
    shouldSwiperUpdate: true,
    getSwiper: (el) => {
      updateSwiper(el);
    },
  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideTo(swiper.activeIndex + 1);
    }
  };
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slideTo(swiper.activeIndex - 1);
    }
  };
  // use NoSSR as this causes issue on boot up of app and user scrolls
  return (
    <NoSSR>
      <Container>
        <Row>
          <Col>
            <Box
              textAlign={['center', null, 'left']}
              display={[null, null, 'flex']}
              alignItems="center"
              justifyContent="space-between"
              mb={['l', null, 'xxl']}
            >
              <Title display="inline" mb={['l', null, 0]}>
                What people are saying
              </Title>
              <Flex mt={[25, 35, 15]} justifyContent="center">
                <Arrow onClick={goPrev} role="button" aria-label="previous">
                  <Icon name="nav-arrow-left" />
                </Arrow>
                <Arrow ml="m" onClick={goNext} role="button" aria-label="next">
                  <Icon name="nav-arrow-right" />
                </Arrow>
              </Flex>
            </Box>
          </Col>
        </Row>
      </Container>
      <SwiperWrapper width="100%" maxWidth="100%">
        <Swiper className="review-swiper" {...params}>
          {reviews.map(({ node }) => (
            <Box className="swiper-slide" key={node.id}>
              <Review
                card
                review={node}
                showRatings={false}
                showReadMore
                readMoreTextCount={220}
              />
            </Box>
          ))}
        </Swiper>
      </SwiperWrapper>
    </NoSSR>
  );
};

SwiperComponent.propTypes = {
  reviews: PropTypes.array,
};
SwiperComponent.defaultProps = {
  reviews: [],
};

export default SwiperComponent;
