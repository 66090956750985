import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Field, Formik } from 'formik';
import { compareValues } from '@firsttable/functions';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import Form from '../../molecules/Form';
import Box from '../../atoms/Box';
import Icon from '../../atoms/Icon';
import Divider from '../../atoms/Divider/Divider';
import FormField from '../../molecules/FormField/FormField';

dayjs.extend(advancedFormat);

const heights = {
  s: '60px',
  m: '90px',
  l: '110px',
};

const DatepickerBox = ({ name, submitForm, onChange }) => {
  const dateRange = [{ value: 'any', label: 'All dates' }];
  for (let i = 0; i <= 6; i += 1) {
    const date = {
      value: dayjs().add(i, 'days').format('YYYY-MM-DD'),
      label: dayjs().add(i, 'days').format('ddd Do MMMM'),
    };
    dateRange.push(date);
  }
  return (
    <Box
      style={{ flexShrink: 0 }}
      position="relative"
      width={['100%', '20%', '230px']}
    >
      <Box
        display={['block', 'none', 'block']}
        zIndex={1}
        position="absolute"
        top={['14px', '40px', '40px']}
        left={['8px', '40px', '40px']}
        style={{
          pointerEvents: 'none',
        }}
      >
        <Icon name="m-calendar" />
      </Box>
      <Box
        display={['none', 'none', 'block']}
        zIndex={1}
        position="absolute"
        top="40px"
        right="30px"
        style={{
          pointerEvents: 'none',
        }}
      >
        <Icon name="s-arrow-down" color="#ccc" />
      </Box>
      <Field
        component={FormField}
        id={name}
        name={name}
        type="select"
        pure
        zIndex={2}
        styles={[
          { height: heights.s, padding: '0 10px 0 35px' },
          { height: heights.m, padding: '0 15px 0 30px' },
          { height: heights.l, padding: '0 20px 0 70px' },
        ]}
        options={dateRange}
        customOnChangeFunc={(e) => {
          onChange(name, e.value);
          setTimeout(() => {
            submitForm();
          });
        }}
      />
    </Box>
  );
};

DatepickerBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

const LocationBox = ({ name, onChange, submitForm }) => (
  <Box
    style={{ flexShrink: 0 }}
    position="relative"
    width={['100%', '20%', '230px']}
  >
    <Box
      display={['block', 'none', 'block']}
      zIndex={1}
      position="absolute"
      top={['15px', '40px', '40px']}
      left={['8px', '40px', '40px']}
      style={{
        pointerEvents: 'none',
      }}
    >
      <Icon name="m-list" />
    </Box>
    <Field
      component={FormField}
      type="select"
      id={name}
      name={name}
      pure
      zIndex={2}
      styles={[
        { height: heights.s, padding: '0 10px 0 35px' },
        { height: heights.m, padding: '0 15px 0 30px' },
        { height: heights.l, padding: '0 20px 0 70px' },
      ]}
      options={[
        { value: 'default', label: 'Discover' },
        { value: 'score', label: 'Top rated' },
        { value: 'distance', label: 'Near me' },
      ]}
      customOnChangeFunc={(e) => {
        onChange(name, e.value);
        setTimeout(() => {
          submitForm();
        });
      }}
    />
  </Box>
);

LocationBox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

function findParentCity(subCities, currentNode) {
  const [parent] = subCities.edges.filter(
    ({ node: parentNode }) => parentNode.foreignId === currentNode.parentId
  );
  return parent?.node;
}

const CitySearchForm = ({
  subCities,
  handleCitySort,
  location,
  initialValues,
  navigate,
}) => {
  const cityOptions = subCities.edges
    .map(({ node }) => {
      let label = node.menuTitle;
      let sortLabel = node.menuTitle;
      let currentNode = node;
      if (node.parentId === 0) {
        label = `All of ${label}`;
      }
      while (currentNode.parentId !== 0) {
        currentNode = findParentCity(subCities, currentNode);
        if (!currentNode || currentNode.parentId === 0) {
          sortLabel = `ZZ ${sortLabel}`;
          break;
        } else {
          label = (
            <>
              <Icon name="s-arrow-right" />
              {` ${label}`}
            </>
          );
          sortLabel = `${currentNode.menuTitle} / ${sortLabel}`;
        }
      }
      return { value: node.slug, label, sortLabel };
    })
    .sort(compareValues('sortLabel'));
  const hasSessionPath = location.pathname.match(/.+?(?=(lunch|breakfast))/g);
  const cityPath = hasSessionPath ? hasSessionPath[0] : location.pathname;
  // canada only has dinner for now
  const searchSessions = [
    { value: 'dinner', label: 'Dinner' },
    { value: 'lunch', label: 'Lunch' },
    { value: 'breakfast', label: 'Breakfast' },
  ];
  if (+process.env.GATSBY_SITE_ID === 4) {
    searchSessions.splice(-2, 2);
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        city: cityPath,
        ...initialValues,
      }}
      onSubmit={(values) => {
        handleCitySort(values);
      }}
    >
      {({ setFieldValue, setFieldTouched, status, submitForm }) => (
        <Form onSubmit={() => {}} status={status}>
          <Box
            colors="card.s"
            display={['block', 'flex']}
            m={['0 -15px', '0', '0']}
            p={['0 15px', '0', '0']}
          >
            <DatepickerBox
              name="selectedDate"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              submitForm={submitForm}
            />
            <Divider
              height={['1px', '90px', '110px']}
              width={['100%', '1px']}
              style={{ flexShrink: 0 }}
            />
            <Box width="100%" position="relative">
              <Box
                display={['block', 'none', 'block']}
                zIndex={1}
                position="absolute"
                top={['14px', '40px', '40px']}
                left={['8px', '40px', '40px']}
                style={{
                  pointerEvents: 'none',
                }}
              >
                <Icon name="m-near-me" />
              </Box>
              <Field
                component={FormField}
                name="city"
                type="select"
                pure
                customOnChangeFunc={(data) => navigate(data.value)}
                styles={[
                  { height: heights.s, padding: '0 10px 0 35px' },
                  { height: heights.m, padding: '0 15px 0 30px' },
                  { height: heights.l, padding: '0 20px 0 70px' },
                ]}
                options={cityOptions}
              />
            </Box>
            <Divider
              height={['1px', '90px', '110px']}
              width={['100%', '1px']}
              style={{ flexShrink: 0 }}
            />
            <LocationBox
              name="restaurantSort"
              onChange={setFieldValue}
              submitForm={submitForm}
              handleCitySort={handleCitySort}
            />
            <Divider
              height={['1px', '90px', '110px']}
              width={['100%', '1px']}
              style={{ flexShrink: 0 }}
            />
            <Box width="100%" position="relative">
              <Box
                display={['block', 'none', 'block']}
                zIndex={1}
                position="absolute"
                top={['14px', '40px', '40px']}
                left={['8px', '40px', '40px']}
                style={{
                  pointerEvents: 'none',
                }}
              >
                <Icon name="m-spoon" />
              </Box>
              <Field
                component={FormField}
                type="select"
                name="session"
                pure
                zIndex={2}
                styles={[
                  { height: heights.s, padding: '0 10px 0 35px' },
                  { height: heights.m, padding: '0 15px 0 30px' },
                  { height: heights.l, padding: '0 20px 0 70px' },
                ]}
                customOnChangeFunc={(e) => {
                  setFieldValue('session', e.value);
                  setTimeout(() => {
                    submitForm();
                  });
                }}
                options={searchSessions}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

CitySearchForm.propTypes = {
  initialValues: PropTypes.shape({
    restaurantSort: PropTypes.string,
    selectedDate: PropTypes.string,
    session: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  subCities: PropTypes.object,
  handleCitySort: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};
CitySearchForm.defaultProps = {
  subCities: {},
  location: {
    pathname: '',
  },
};

export default CitySearchForm;
