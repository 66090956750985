import React from 'react';
import PropTypes, { string } from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Field } from 'formik';
import moment from 'moment';
import { currencyFormat } from '@firsttable/functions';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import media from 'styled-media-query';
import FormLayout from './Form';
import FormField from '../../molecules/FormField/FormField';
import BookingTable from '../../molecules/BookingTable';
import CardSection from './Fields/CardSection';
import ExtraDinersField from './Fields/ExtraDinersField';
import { Row, Col, BodyTitle, Text, Box, Button, Title } from '../../atoms';

const StyledPaymentBox = styled(Box)`
  background: linear-gradient(180deg, #f5ede4 0%, #fff 100%);
  padding: 58px 64px;
  ${media.lessThan(bp.s)`
    padding: 24px;
    margin: 40px -16px 0;
    background: ${themeGet('colors.gold.400')};
  `};
`;

const StyledDL = styled.dl``;

const StyledDT = styled.dt`
  float: left;
  clear: left;
  text-align: left;
  color: ${themeGet('colors.grayscale.600')};
  padding-right: 7px;
  font-size: 16px;
`;

const StyledDD = styled.dd`
  margin: 0 0 0 3px;
  padding: 0 0 5px 0;
  font-size: 16px;
`;

const Sticky = styled(Box)`
  position: -webkit-sticky;
  position: sticky;
`;

const BookingForm = ({
  handleSubmit,
  values,
  booking,
  user,
  leaveTime,
  handleChange,
  handleBlur,
  paymentOptions,
  setFieldValue,
  isSubmitting,
  specialConditions,
  ...props
}) => (
  <FormLayout handleSubmit={handleSubmit} {...props}>
    <Row>
      <Col width={[1, 1 / 2]}>
        <Field
          component={FormField}
          type="text"
          name="name"
          id="Name"
          label="Name for reservation"
          placeholder="Name for reservation"
          mb={['15px', '30px']}
        />
        <Field
          component={FormField}
          name="email"
          id="Email"
          type="email"
          label="Email for reservation"
          placeholder="Email address"
          mb={['15px', '30px']}
        />
        <Field
          component={FormField}
          type="tel"
          name="phone"
          id="Phone"
          label="Phone for reservation"
          placeholder="Phone number"
          mb={['15px', '30px']}
        />
        <BookingTable
          maxWidth="100%"
          name="people"
          selected={+values.people}
          leaveTime={leaveTime}
          onChange={setFieldValue}
          onBlur={handleBlur}
          restaurant={booking.restaurant}
          allowedDiners={booking.availability.allowedDiners}
          options={[{ inputValue: 2 }, { inputValue: 3 }, { inputValue: 4 }]}
        />
        {booking.restaurant.additionalPeopleForbidden && (
          <BodyTitle size="s" m="20px 0">
            This restaurant explicitly doesn&lsquo;t allow additional diners to{' '}
            be added to tables.
          </BodyTitle>
        )}
        <ExtraDinersField booking={booking} disabled={values.people < 4} />
        {booking.restaurant.askForDietaryRequirements && (
          <>
            <BodyTitle size="s" m="20px 0 0">
              Dietary requirements
            </BodyTitle>
            <Field
              component={FormField}
              type="textarea"
              name="dietaryRequirements"
              id="dietaryRequirements"
              label="Please list any dietary requirements here:"
            />
          </>
        )}
        {!user.contactPermission && (
          <>
            <BodyTitle size="s" m="20px 0 0">
              {`Can we send you updates when new restaurants join in ${booking.restaurant.region.menuTitle}?`}
            </BodyTitle>
            <Field
              component={FormField}
              type="radio"
              name="contactPermission"
              id="contactPermission"
              options={[
                {
                  inputLabel: 'No thanks (not recommended)',
                  inputValue: '0',
                },
                { inputLabel: 'Yes please', inputValue: '1' },
              ]}
            />
          </>
        )}
        <BodyTitle size="s" m="20px 0 0">
          {`Have you previously dined at ${booking.restaurant.title}?`}
        </BodyTitle>
        <Field
          component={FormField}
          type="radio"
          name="dinedBefore"
          id="dinedBefore"
          label="Have you dined before?"
          inline
          options={[
            { inputLabel: 'Yes', inputValue: '1' },
            { inputLabel: 'No', inputValue: '0' },
          ]}
          m="12px 0"
        />
        <BodyTitle size="s" m="20px 0 0">
          Are you happy to leave a review afterwards?
        </BodyTitle>
        <Text color="grayscale.600" m="0">
          Help guide our foodie community to the right spots by sharing your
          experience!
        </Text>
        <Field
          component={FormField}
          type="radio"
          name="leaveReview"
          id="leaveReview"
          label="Great reviews keen restaurants on First Table, and help us to sign up more!"
          inline
          options={[
            { inputLabel: 'Yes', inputValue: '1' },
            { inputLabel: 'No', inputValue: '0' },
          ]}
          m="12px 0"
        />
        <Field
          component={FormField}
          type="checkbox"
          name="news"
          id="news"
          label={`I would like to receive news and offers from ${booking.restaurant.title}`}
          m="30px 0 20px 0"
        />
      </Col>
      <Col width={[1, 1 / 2]}>
        <Sticky top={0}>
          <StyledPaymentBox>
            <Title size="h4">
              {moment(booking.time, 'HH:mm:ss').format('h:mm A')},{' '}
              {moment(booking.date).format('dddd')}
              <br />
              {moment(booking.date).format('D MMMM')}
            </Title>
            <BodyTitle size="s" fontWeight="600">
              {booking.restaurant.title}
            </BodyTitle>
            <StyledDL>
              <StyledDT>Price:</StyledDT>
              <StyledDD>
                {booking.price
                  ? currencyFormat(booking.price, booking.currency)
                  : 'Complimentary'}
              </StyledDD>
              {booking.credit > 0 && (
                <>
                  <StyledDT>Your Credit:</StyledDT>
                  <StyledDD>
                    {currencyFormat(booking.credit, booking.currency)}
                  </StyledDD>
                </>
              )}
              {booking.amountToPay < booking.price && (
                <>
                  <StyledDT>To Pay now:</StyledDT>
                  <StyledDD>
                    {currencyFormat(booking.amountToPay, booking.currency)}
                  </StyledDD>
                </>
              )}
              <StyledDT>Discount:</StyledDT>
              <StyledDD>50% off the food bill</StyledDD>
            </StyledDL>

            {booking.amountToPay > 0 && (
              <>
                <Field
                  component={FormField}
                  type="radio"
                  name="payment"
                  id="payment"
                  label="Great reviews keen restaurants on First Table, and help us to sign up more!"
                  options={paymentOptions.sort((a, b) => a.sort - b.sort)}
                />
                {['NewPaymentIntention', 'NewCard'].includes(
                  values.payment
                ) && (
                  <>
                    <CardSection
                      setFieldValue={setFieldValue}
                      type={values.payment}
                    />
                    {['NewCard'].includes(values.payment) && (
                      <Field
                        component={FormField}
                        name="saveCard"
                        type="checkbox"
                        id="saveCard"
                        label="Securely save this card for next time?"
                      />
                    )}
                  </>
                )}
              </>
            )}
            <Field
              component={FormField}
              type="checkbox"
              name="conditions"
              id="conditions"
              label="I accept the below conditions of purchase and dining"
              m="20px 0"
            />
            <Button
              type="submit"
              kind="cta"
              m="10px 0"
              wide
              isLoading={isSubmitting}
            >
              {booking.amountToPay > 0
                ? `Pay ${currencyFormat(booking.amountToPay, booking.currency)}`
                : 'Book table'}
            </Button>
          </StyledPaymentBox>
        </Sticky>
      </Col>
    </Row>
    {specialConditions && (
      <Row mt={['20px', '100px']}>
        <Col>
          <BodyTitle m="20px 0 10px" fontWeight="600" color="grayscale.600">
            Conditions
          </BodyTitle>
          {specialConditions}
        </Col>
      </Row>
    )}
  </FormLayout>
);

BookingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    people: PropTypes.number,
    payment: string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  booking: PropTypes.shape({
    amountToPay: PropTypes.number,
    currency: PropTypes.string,
    restaurant: PropTypes.shape(),
    price: PropTypes.number,
    time: PropTypes.string,
    date: PropTypes.string,
    availability: PropTypes.shape(),
    credit: PropTypes.number,
  }).isRequired,
  user: PropTypes.shape({
    contactPermission: PropTypes.bool,
  }).isRequired,
  leaveTime: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  paymentOptions: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  specialConditions: PropTypes.object,
};
BookingForm.defaultProps = {
  leaveTime: null,
};

export default BookingForm;
