import React from 'react';
import Analytics from 'analytics';
import { AnalyticsProvider } from 'use-analytics';
import Cookies from 'js-cookie';
import googleTagManager from '../../lib/tag-manager';
// import TrackingContext from '../context/TrackingContext';

const isProduction = process.env.NODE_ENV === 'production';
const isDev = process.env.NODE_ENV === 'development';
const isUK = parseInt(process.env.NEXT_PUBLIC_SITE_ID, 10) === 8;

const shouldTrack = (): boolean => {
  const cookie = Cookies.get('CookieConsentFT');
  // console.log('should?', {
  //   cookie,
  //   isUK,
  // });
  switch (cookie) {
    case undefined:
      return !isUK;
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return true;
  }
};

// GTM
const devOnlyPlugins = [
  googleTagManager({
    containerId: 'GTM-N9ZTBPD',
    enabled: shouldTrack(),
  }),
  {
    name: 'logger',
    page: ({ payload }) => {
      console.log('page', payload);
    },
    track: ({ payload }) => {
      console.log('track', payload);
    },
    identify: ({ payload }) => {
      console.log('identify', payload);
    },
  },
];

const productionPlugins = [
  googleTagManager({
    containerId: 'GTM-NPDFL7S',
    enabled: shouldTrack(),
  }),
];

const plugins = [...(isDev ? devOnlyPlugins : []), ...(isProduction ? productionPlugins : [])];

const analyticsSettings = {
  app: `myAppName`,
  plugins,
  debug: true,
};

const analytics = Analytics(analyticsSettings);

export const enableAnalytics = (): void => {
  if (process.env.NEXT_PUBLIC_ANALYTICS) {
    analytics.plugins.enable(['google-tag-manager']).then(() => {
      /* Track a page view */
      analytics.track('pageview');
    });
  }
};

// eslint-disable-next-line react/display-name
const withTracking = (ComponentToWrap: any) => (props: any) => {
  // const consentContext = useContext(TrackingContext);
  // Consented to statistical data/gdpr
  // if (consentContext.shouldTrack) {
  //   analytics.plugins.enable('google-tag-manager');
  // }

  if (!analytics) return <ComponentToWrap {...props} analytics={analytics} />;

  return (
    <AnalyticsProvider instance={analytics}>
      <ComponentToWrap {...props} analytics={analytics} />
    </AnalyticsProvider>
  );
};

export default withTracking;
