import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Amex = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "amex.png" }) {
        childImageSharp {
          fixed(height: 20) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Img
      fixed={data.file.childImageSharp.fixed}
      alt="Accepts American Express"
      title="Accepts American Express"
    />
  );
};

export default Amex;
