import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Item from '@firsttable/web-components/organisms/Restaurant/Item/RestaurantItem';
import { Divider } from '@firsttable/web-components/atoms';
import { Restaurant as RestaurantType } from '@firsttable/types';
import AvailabilityComponent from '../Availability';
import withFirebase from '../../../hocs/withFireBaseAvailability';
import Amex from '../../Amex';
import NavLink from '../../atoms/NavLink';

const ListDivider = styled(Divider)`
  &:first-child {
    display: none;
  }
`;

type RestaurantListItemProps = {
  restaurant: RestaurantType;
  availabilitySession: string;
  selectedDate: string;
  isLoading: boolean;
  availability: any;
  setCurrentSession: (s: string) => void;
  currentSession: string;
  AvailabilitySessionBtn: any;
};
// Current set orderByValue and equalTo to allows us
// to re-render/break render cache availability items
// availability again
const WrappedItem = ({
  restaurant,
  availabilitySession,
  selectedDate,
  isLoading,
  availability,
  setCurrentSession,
  currentSession,
  ...props
}: RestaurantListItemProps) => (
  <>
    <ListDivider mb="30px" />
    <Item
      {...props}
      availabilitySession={availabilitySession}
      restaurant={restaurant}
      isLoading={isLoading}
      availability={availability}
      AvailabilityComponent={AvailabilityComponent}
      selectedDate={selectedDate}
      AmexBox={Amex}
      NavLinkComponent={NavLink}
      showLastTableLayout={
        restaurant.availabilitySessions &&
        restaurant.availabilitySessions.includes('dinner2') &&
        availabilitySession === 'dinner'
      }
      currentSession={currentSession}
      setSession={setCurrentSession}
    />
  </>
);

WrappedItem.propTypes = {
  restaurant: PropTypes.object.isRequired,
  availabilitySession: PropTypes.string.isRequired,
  // restaurantSort: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  selectedDate: PropTypes.string,
  isLoading: PropTypes.bool,
  availability: PropTypes.object.isRequired,
  setCurrentSession: PropTypes.func.isRequired,
  currentSession: PropTypes.string.isRequired,
};

WrappedItem.defaultProps = {
  selectedDate: '',
  // restaurantSort: 'rated',
  isLoading: true,
};

export default withFirebase(WrappedItem);
