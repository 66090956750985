import React from 'react';
import PropTypes from 'prop-types';
import { Restaurant, RatingSummary } from '@firsttable/types';
import Rating from '../Restaurant/Rating';
import Box from '../../atoms/Box';
import Flex from '../../atoms/Flex';
import Title from '../../atoms/Title';
import Divider from '../../atoms/Divider';

const RestaurantReviews = ({ restaurant }: { restaurant: Restaurant }) => {
  // const { restaurant } = props;
  // default to 5 for now while there is a bug with ratingSummary data
  let serviceRating = {
    average: 5,
  } as RatingSummary;
  let foodRating = {
    average: 5,
  } as RatingSummary;

  if (restaurant.ratingSummaries?.length > 0) {
    [foodRating] = restaurant.ratingSummaries.filter(
      (rating) => rating.title === 'Food'
    );
    [serviceRating] = restaurant.ratingSummaries.filter(
      (rating) => rating.title === 'Service'
    );
  }
  return (
    <Box
      id="RestaurantReviews"
      borderBottom="1px solid"
      borderColor="grayscale.300"
      mb={['m', 'xxl']}
      pb={['xs', 'l']}
    >
      <Title mb={['s', 'm']}>What people say</Title>
      <Box display={['block', 'flex']} flexWrap="wrap" alignItems="flex-start">
        <Box mr="xxl" mb="s">
          Overview
          <Divider
            top="-5px"
            left="3px"
            display="inline-block"
            style={{ transform: 'rotate(-67.69deg)' }}
            bg="black"
            height="1px"
            width="29px"
          />{' '}
          {restaurant.reviewsCount} reviews
        </Box>
        <Flex flexWrap="wrap" mb="s">
          <Flex mr="35px">
            <Box color="grayscale.600" mr="19px">
              Food
            </Box>
            <Rating rating={foodRating && foodRating.average} mt="-1px" />
          </Flex>
          <Flex>
            <Box color="grayscale.600" mr="19px">
              Service
            </Box>
            <Rating rating={serviceRating && serviceRating.average} mt="-1px" />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

RestaurantReviews.propTypes = {
  restaurant: PropTypes.shape({
    title: PropTypes.string,
    ratingOutOfTen: PropTypes.number,
    ratingSummaries: PropTypes.array,
    reviewsCount: PropTypes.number,
  }).isRequired,
};

export default RestaurantReviews;
