import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Col,
  Container,
  Link,
  Row,
  Text,
  Icon, Title,
} from '@firsttable/web-components/atoms';
import InfoBlock from './InfoBlock';
import imgBirdSignIn from '../Modal/img/bird-sign-in.svg';
import imgBirdSignUn from '../Modal/img/bird-sign-up.svg';
import ModalContext from '../../../context/ModalContext';
import withLocation from '../../../hocs/location';
import NavLinkComponent from '../../atoms/NavLink';

const BackToHomeBtn = (props) => (
  <Box mb="l" mt={[-8, 0]}>
    <Link color="brand.blue" {...props} NavComponent={NavLinkComponent}>
      <Box display="inline-flex" alignItems="center">
        <Icon name="m-arrow-left" color={null} mr="xs" />
        <Text fontSize="s" fontWeight="bold" mb={0}>
          Back to home
        </Text>
      </Box>
    </Link>
  </Box>
);

const AuthLayout = ({
  children, signIn, signUp, location: { pathname },
}) => {
  const { hideModalFunc, isModalOpen } = useContext(ModalContext);
  return (
    <Container
      maxWidth={null}
      pl={null}
      pr={null}
    >
      <Row gap={0}>
        <Col
          width={[1, 1 / 2]}
          minHeight={[null, '100vh']}
          display="flex"
          flexDirection="column"
          px="l"
          pt="l"
          pb={['xl', 56]}
        >
          {isModalOpen && pathname === '/' ? (
            <BackToHomeBtn onClick={() => hideModalFunc()} />
          ) : (
            <BackToHomeBtn to="/" />
          )}
          <Box display="flex" flex={1}>
            <Box m="auto" maxWidth={400} flex={1}>
              <Box textAlign="center" mb="xxl">
                {signIn && (
                  <img src={imgBirdSignIn} alt="Sign in to First Table" />
                )}
                {signUp && (
                  <Link to="/" NavComponent={NavLinkComponent}><img src={imgBirdSignUn} alt="Sign up to First Table" /></Link>
                )}
              </Box>
              {children}
            </Box>
          </Box>
        </Col>
        <InfoBlock>
          <Title
            color={null}
            as="h1"
            fontSize={['heading.h4', 'heading.h3', 'heading.hero']}
          >
            There&rsquo;s never been a better time to tuck in
          </Title>
          <Text>
            Book a First Table at our partner restaurants and get 50% off the
            {' '}
            food bill for two to four people.
            {' '}
            Talk about the early bird catches the worm!
          </Text>
          <Text fontSize="s" mb={0}>
            First Table 2014 -
            {' '}
            {(new Date()).getFullYear()}
            {' '}
            <Link to="/privacy-policy" NavComponent={NavLinkComponent} underline>Privacy and Terms</Link>
          </Text>
        </InfoBlock>
      </Row>
    </Container>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
  signIn: PropTypes.bool,
  signUp: PropTypes.bool,
  location: PropTypes.shape(),
};

export default withLocation(AuthLayout);
