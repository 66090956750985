import React from 'react';
import useGlobalHook from './global';

const initialState = {
  processing: false,
  bookingId: null,
  status: null,
};

const actions = {
  setBookingState: (store, bookingState) => {
    store.setState({ ...bookingState });
  },
};

const useGlobal = useGlobalHook(React, initialState, actions);

const useBookingState = () => {
  const [bookingState, setBookingState] = useGlobal();
  return {
    bookingState,
    ...setBookingState,
  };
};

export default useBookingState;
