import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { themeGet } from '@styled-system/theme-get';
import { switchProp } from 'styled-tools';
import { PositionProps, SpaceProps } from 'styled-system';
import Box from '../../atoms/Box';
import Icon from '../../atoms/Icon';

const Wrapper = styled(Box)`
  display: inline-block;
  vertical-align: middle;
  position: relative;
`;
type RankBoxType = {
  name?: string;
};

const RankBox = styled(Box)<RankBoxType>`
  position: absolute;
  left: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  ${switchProp('name', {
    's-rank': css`
      top: 12px;
      font-weight: bold;
      padding-left: 1px;
      font-size: ${themeGet('fontSizes.xxs')}px;
    `,
    'l-rank': css`
      top: 14px;
      font-size: ${themeGet('fontSizes.bodyHeading.s')}px;
      font-family: ${themeGet('fonts.headings')};
    `,
  })};
`;

const RankComponent: any = ({
  rank,
  name,
  ...props
}: {
  rank: number;
  name: any;
} & PositionProps &
  SpaceProps) => {
  if (rank === 0) {
    return null;
  }

  return (
    <Wrapper {...props}>
      <Icon name={name} />
      <RankBox name={name}>{Math.round(rank)}</RankBox>
    </Wrapper>
  );
};

RankComponent.propTypes = {
  name: PropTypes.oneOf(['s-rank', 'l-rank']),
  rank: PropTypes.number.isRequired,
};

RankComponent.defaultProps = {
  name: 's-rank',
};

export default RankComponent;
