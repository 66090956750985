import React from 'react';
import PropTypes from 'prop-types';
import Div100vh from 'react-div-100vh';
import RestaurantMap from '../../RestaurantMap';

const MapModal = ({ restaurant }) => {
  let session = 'dinner';
  if (!restaurant.availabilitySessions.includes(session)) {
    [session] = restaurant.availabilitySessions;
  }
  return (
    <Div100vh>
      <RestaurantMap restaurants={[restaurant]} session={session} />
    </Div100vh>
  );
};

MapModal.propTypes = {
  restaurant: PropTypes.object.isRequired,
};
MapModal.defaultProps = {};

export default MapModal;
