import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';

const withAuth = (ComponentToWrap) => {
  const WithAuthContext = (props) => {
    const authContext = useContext(AuthContext);
    return <ComponentToWrap {...authContext} {...props} />;
  };
  WithAuthContext.displayName = 'withAuthContext';

  return WithAuthContext;
};

export default withAuth;
