import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { awsImage } from '@firsttable/functions';
import { breakpoints } from '@firsttable/web-theme';
import LazyLoadImageWrapper from '../styles';
// todo fix for next-web
// import 'react-lazy-load-image-component/src/effects/opacity.css';

const Cover = ({ gallery, title }) => {
  const size = {
    m: { width: 800, height: 800 },
    s: { width: 768, height: 475 },
  };
  const { s: breakpoint } = breakpoints;
  const bp = breakpoint.replace(/px/, '');
  const [loaded, setLoaded] = useState('');
  return (
    <LazyLoadImageWrapper min={bp} max={bp - 1}>
      <span
        className={`lazyload-img-wrapper lazy-load-image-background opacity ${loaded}`}
      >
        <LazyLoadComponent>
          <picture>
            <source
              media={`(max-width: ${bp - 1}px)`}
              srcSet={`${awsImage(gallery, size.s.width, size.s.height)}`}
            />
            <source
              media={`(min-width: ${bp}px)`}
              srcSet={`${awsImage(gallery, size.m.width, size.m.height)}`}
            />
            <img
              onLoad={() => setLoaded('lazy-load-image-loaded')}
              className="img-fluid"
              src={awsImage(gallery, size.m.height, size.m.height)}
              alt={title}
            />
          </picture>
        </LazyLoadComponent>
      </span>
    </LazyLoadImageWrapper>
  );
};

Cover.propTypes = {
  title: PropTypes.string,
  gallery: PropTypes.string,
};

export default Cover;
