import React from 'react';
import Cookies from 'js-cookie';

const withCookie = (ComponentToWrap: any) => {
  const WithCookie = (props: any) => (
    <ComponentToWrap {...props} newUserSignUp={!!Cookies.get('userSignedUp')} />
  );
  WithCookie.displayName = 'withCookie';

  return WithCookie;
};

export default withCookie;
