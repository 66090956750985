import React from 'react';
import styled from 'styled-components';
import Alert from '../../atoms/Alert';
import { BoxProps } from '../../atoms/Box/Box';
import { AlertProps } from '../../atoms/Alert/Alert';

const StyledAlert = styled(Alert)`
  position: relative;
  border-radius: 0;
  display: flex;
  justify-content: center;
  transition: all 500ms ease-out;
`;

const AlertBanner = (props: BoxProps & AlertProps) => (
  <StyledAlert {...props} />
);

export default AlertBanner;
