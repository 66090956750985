/* eslint-disable */
import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ModalComponent from '@firsttable/web-components/molecules/Modal/Modal';
import SignIn from '../Auth/SignIn';
import Icon from '@firsttable/web-components/atoms/Icon/Icon';
import Box from '@firsttable/web-components/atoms/Box/Box';
import SignUp from '../Auth/SignUp';
import ModalContext from '../../../context/ModalContext';
import MapModal from './Restaurant/Map';
import ModalTheme from '@firsttable/web-theme/modal';

const StyledCloseButton = styled(Box)`
  //z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

class Modal extends Component {
  componentDidMount() {
    const {
      shouldShowModal, hideModal,
    } = this.props;
    if (typeof window !== 'undefined') {
      if (shouldShowModal) {
        hideModal();
      }
    }
  }

  modalToDisplay() {
    const { modal, options: { data } } = this.props;
    if (React.isValidElement(modal)) {
      return modal;
    }
    switch (modal) {
      case 'login':
        return <SignIn />;
      case 'signUp':
        return <SignUp isModal={true} />;
      case 'restaurantMap':
        return <MapModal restaurant={data} />;
      default: return <div>No modal</div>;
    }
  }

  isLightBox() {
    const { modal, options } = this.props;
    const modalTypeToShow = options.type || modal;
    return modalTypeToShow === 'galleryLightBox' || modalTypeToShow === 'lightBox' || modalTypeToShow === 'galleryLightBox' || modalTypeToShow === 'fbShare';
  }

  closeIconColor() {
    const { modal, options } = this.props;
    if (options.closeIconColor) {
      return options.closeIconColor;
    }
    const modalTypeToShow = options.type || modal;
    switch (modalTypeToShow) {
      case 'login':
      case 'signUp': return ['brand.blue', 'white'];
      case 'fbShare':
      case 'narrow':
      case 'galleryLightBox': return 'white';
      default: return 'brand.blue';
    }
  }

  backgroundColor() {
    const { modal, options } = this.props;
    const modalTypeToShow = options.type || modal;
    switch (modalTypeToShow) {
      case 'fbShare':
      case 'restaurantConditions':
      case 'narrow':
        return 'rgba(0, 0, 0, 0.4)';
      default: return null;
    }
  }

  render() {
    const { shouldShowModal, hideModal, options } = this.props;

    const modal = this.modalToDisplay();
    const isLightBox = this.isLightBox();
    const backgroundColor = this.backgroundColor();
    return (
      <ModalComponent
        handleCloseModal={hideModal}
        isOpen={shouldShowModal}
        isLightBox={isLightBox}
        background={backgroundColor}
      >
        <StyledCloseButton
          width={['75px', '100px']}
          height={['75px', '100px']}
          right={[0, null, '30px']}
          top={[0, null, '30px']}
          role="button"
          onClick={() => hideModal()}
        >
          {!options.disableCloseBtn && <Icon name="nav-close" color={this.closeIconColor()} />}
        </StyledCloseButton>
        <ModalTheme>
          {modal}
        </ModalTheme>
      </ModalComponent>
    );
  }
}

Modal.propTypes = {
  shouldShowModal: PropTypes.bool,
  modal: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  ModalComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  modalType: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  options: PropTypes.shape({}),
};

Modal.defaultProps = {
  shouldShowModal: false,
  modal: '',
  showModal: () => {},
  options: {
    disableCloseBtn: false,
    disableEscExit: false,
    type: 'none',
    data: {}
  },
};

const
  ContextWrappedModal = (props) => {
  const { hideModalFunc, data, showModalFunc } = useContext(ModalContext);
  return (
    <Modal
      shouldShowModal={data.showModal}
      modal={data.modal}
      options={data.options}
      hideModal={hideModalFunc}
      showModal={showModalFunc}
      {...props}
    />
  );
};

ContextWrappedModal.propTypes = {
  modalData: PropTypes.shape(),
};
ContextWrappedModal.defaultProps = {};

export default ContextWrappedModal;
