import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { awsImage } from '@firsttable/functions';
import Img from 'gatsby-image';
import { Flex, BodyTitle, Text, Link, Box } from '../../atoms';

const textOverflowHiddenStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
};

const CardLink = ({ item, children, ...props }) =>
  item.slug ? (
    <Link to={item.slug} {...props}>
      {children}
    </Link>
  ) : (
    <a href={item.href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );

CardLink.propTypes = {
  item: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
};

const HorizontalCard = ({
  item,
  subTitle,
  width,
  img,
  dataImg,
  NavLinkComponent,
}) => {
  const imgHeight = 100;

  return (
    <CardLink item={item} NavComponent={NavLinkComponent}>
      <Box colors="card.s">
        <Flex style={{ alignItems: 'center' }}>
          <Box bg="#ebeff2" height={imgHeight}>
            {img && (
              <LazyLoadImage
                srcSet={`${awsImage(img.url, width, imgHeight)} 1x, ${awsImage(
                  img.url,
                  width * 2,
                  imgHeight * 2
                )} 2x`}
                src={awsImage(img.url, width, imgHeight)}
                alt={item.title}
                className="img-fluid"
                effect="opacity"
              />
            )}
            {dataImg && <Img fixed={dataImg} />}
          </Box>
          <Box p="0 25px" minWidth="0">
            <BodyTitle
              size="xs"
              mb={0}
              style={{ flex: 1, ...textOverflowHiddenStyles }}
            >
              {item.title}
            </BodyTitle>
            <Text fontSize="xs" lineHeight="xs" color="grayscale.600" mb="0">
              {subTitle || <span>&nbsp;</span>}
            </Text>
          </Box>
        </Flex>
      </Box>
    </CardLink>
  );
};

HorizontalCard.propTypes = {
  item: PropTypes.shape().isRequired,
  width: PropTypes.number,
  subTitle: PropTypes.string,
  img: PropTypes.shape(),
  dataImg: PropTypes.shape(),
  NavLinkComponent: PropTypes.any.isRequired,
};
HorizontalCard.defaultProps = {
  width: 285,
  subTitle: null,
  img: null,
  dataImg: null,
};

export default HorizontalCard;
