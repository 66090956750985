import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import media from 'styled-media-query';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import styledSpaces from '@firsttable/web-theme/styledSpaces';
import { awsImage, dayMonthYear } from '@firsttable/functions';
import Rating from '../Restaurant/Rating';
import { Flex, Box, Text, Icon, Avatar, Link } from '../../atoms';

const Wrapper = styled(Flex)`
  ${({ card }) =>
    card === 'true' &&
    css &&
    media.greaterThan(bp.s)`
    position: relative;
    max-width: 590px;
    padding: 30px 40px 60px 0;
    
    &::before {
      content: " ";
      position: absolute;
      top: 10px;
      left: 13px;
      right: 11px;
      bottom: 13px;
      border-radius: 6px;
      transform: rotate(2deg) skew(-3deg, 0);
      ${themeGet('colorStyles.card.m')};
    }
  `};
`;
const IconQuote = styled(Box)`
  position: absolute;
  bottom: 46px;
  right: 67px;
  ${({ readmore }) =>
    readmore
      ? css`
          bottom: 28px;
        `
      : null}
`;

const RoundAvatar = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 56px;
  height: 56px;
  ${media.lessThan(bp.s)`
      width: 32px;
      height: 32px;
  `};
`;

const ReadMoreText = ({ comment, max }) => {
  if (!comment) return comment;
  const cut = comment.indexOf(' ', max);
  if (cut === -1) return comment;
  const [isExtended, setIsExtended] = useState(false);
  return !isExtended ? (
    <>
      {comment.substring(0, cut)}
      ... {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link underline onClick={() => setIsExtended(true)}>
        Read more
      </Link>
    </>
  ) : (
    comment
  );
};
ReadMoreText.propTypes = {
  comment: PropTypes.string,
  max: PropTypes.number,
};
ReadMoreText.defaultProps = {
  comment: '',
  max: 120,
};

const ReviewCard = ({
  name,
  card,
  review,
  showRatings,
  showReadMore,
  photos,
  date,
  userType,
  comment,
  subTextPrefix,
  readMoreTextCount,
  ...rest
}) => {
  const spaces = styledSpaces(rest);
  const [avatar, setAvatar] = useState('');
  const isRestaurant = userType === 'Restaurant';
  const isStaff = userType === 'First Table';
  const restaurantLogo = awsImage(
    get(review, 'restaurant.logo.url'),
    100,
    100,
    'https://img.firsttable.net/'
  );
  const userAvatar = avatar
    .replace(/=36/gim, '=100')
    .replace(/36x36/, '100x100');
  const currentAvatar = isRestaurant ? restaurantLogo : userAvatar;

  const restaurant = review.restaurant.title;

  useEffect(() => {
    if (review.userAvatar) {
      setAvatar(review.userAvatar);
    }
  }, [review]);

  return (
    <Wrapper {...spaces} card={card.toString()}>
      <Flex position="relative">
        <Box mr={[12, 'm']} width={[32, 56]} mt={[-8, -5]}>
          {currentAvatar && !isStaff ? (
            <RoundAvatar>
              <RoundAvatar
                src={currentAvatar}
                alt=""
                className="img-fluid"
                onError={() => setAvatar('')}
              />
            </RoundAvatar>
          ) : (
            <Avatar
              color={isStaff ? 'brand.blue' : 'grayscale.400'}
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMin meet"
            />
          )}
        </Box>
        <Box flex={1} mt={-6} maxWidth={card ? [null, 425] : null}>
          <Text
            fontSize={18}
            mb={[12, 'xs']}
            fontWeight="bold"
            style={{ textTransform: 'capitalize' }}
          >
            <Box as="span">
              {name || 'Visitor'} {isStaff && <> - First Table</>}
              {isRestaurant && <>- {restaurant}</>}
            </Box>
          </Text>
          <Box ml={[-44, 0]}>
            {!(isRestaurant || isStaff) && (
              <Text mt={-6} mb={12} color="grayscale.600">
                <>
                  {restaurant
                    ? `${subTextPrefix}${review.restaurant.title}`
                    : 'Dined '}
                  {date && <Box as="span"> on {dayMonthYear(date)}</Box>}
                </>
              </Text>
            )}

            <Text mb={showRatings ? 20 : 0}>
              {showReadMore ? (
                <ReadMoreText comment={comment} max={readMoreTextCount} />
              ) : (
                comment
              )}
            </Text>

            {photos && photos.length > 0 && (
              <Box mb="12px">
                <Text color="grayscale.600" mb="14px">
                  Uploaded photos
                </Text>
                <Flex>
                  {photos.map(({ id, photo }) => (
                    <Box role="button" key={id} m="0 8px 8px 0" maxWidth="76px">
                      <img src={photo} alt="" className="img-fluid" />
                    </Box>
                  ))}
                </Flex>
              </Box>
            )}
            {showRatings && (
              <Box color="grayscale.600" display={[null, 'flex']}>
                <Flex mr="35px">
                  <Box mr="19px">Food</Box>
                  <Rating rating={review.foodRating} mt="-1px" />
                </Flex>
                <Flex>
                  <Box mr="19px">Service</Box>
                  <Rating rating={review.serviceRating} mt="-1px" />
                </Flex>
              </Box>
            )}
          </Box>
        </Box>
      </Flex>
      {card && (
        <IconQuote
          display={['none', 'block']}
          readmore={showReadMore ? '1' : '0'}
        >
          <Icon name="m-quote" />
        </IconQuote>
      )}
    </Wrapper>
  );
};

ReviewCard.propTypes = {
  review: PropTypes.object,
  photos: PropTypes.array,
  card: PropTypes.bool,
  showRatings: PropTypes.bool,
  showReadMore: PropTypes.bool,
  readMoreTextCount: PropTypes.number,
  subTextPrefix: PropTypes.string,
  date: PropTypes.string,
  name: PropTypes.string,
  comment: PropTypes.string,
  userType: PropTypes.string,
};

ReviewCard.defaultProps = {
  review: {},
  photos: [],
  card: false,
  showRatings: true,
  showReadMore: true,
  readMoreTextCount: 120,
  subTextPrefix: 'Dined at ',
  date: '',
  name: '',
  comment: '',
  userType: '',
};

export default ReviewCard;
