import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight } from 'lodash';
import { navigate } from 'gatsby';
import gql from 'graphql-tag';
import { createBooking as CREATE_BOOKING } from '@firsttable/graphql-queries/src';
import { errorMessage } from '@firsttable/functions';
import { Box, Flex } from '@firsttable/web-components/atoms';
import { withAlert } from 'react-alert';
import Cookies from 'js-cookie';
import withModal from './withModal';
import SignInForm from '../components/organisms/Forms/SignInForm';
import imgBirdSignIn from '../components/organisms/Modal/img/bird-sign-in.svg';
import withBackUrl from './backUrl';

const SignInModal = () => (
  <Flex height="100vh">
    <Box m="auto" width={['100%', '600px']}>
      <Box
        position="relative"
        width={['100%', '600px']}
        p={['40px 16px', '56px 64px']}
        bg="white"
      >
        <Box textAlign="center" mb="xxl">
          <img src={imgBirdSignIn} alt="Sign in to First Table" />
        </Box>
        <SignInForm />
      </Box>
    </Box>
  </Flex>
);

const CANCEL_BOOKING = gql`
  mutation cancelBooking($id: Int) {
    cancelBooking(id: $id) {
      status
    }
  }
`;

const withHandleBooking = (WrappedComponent) => {
  class BookingComponent extends Component {
    constructor(props) {
      super(props);
      this.handleBooking = this.handleBooking.bind(this);
      this.cancelBooking = this.cancelBooking.bind(this);
      this.processBooking = this.processBooking.bind(this);
      this.state = {
        processing: false,
        processingId: null,
        bookingStatus: 'Confirmed', // assume confirmed as data should fetch only confirmed bookings
      };
    }

    /**
     * Prevent multiple clicks on booking
     * @param bookingData
     * @returns {null|void}
     */
    processBooking(bookingData) {
      const { processing } = this.state;
      if (processing) {
        return null;
      }
      return this.handleBooking(bookingData);
    }

    handleBooking({ id, date, time, session }) {
      // 1. create mutation to create booking on site.
      const { createBooking, restaurant, showModal, setBackUrl, alert } =
        this.props;
      this.setState({ processing: true, processingId: +id });

      createBooking({
        variables: {
          restaurantId: +restaurant.foreignId,
          date,
          time,
          availabilityId: +id,
        },
      })
        .then(async ({ data, error }) => {
          if (!error) {
            await navigate(`/booking/${data.createBooking.reference}/details`);
          } else {
            this.setState({ processing: false, processingId: null });
            // 2b. if there was an error creating the booking externally.
            // Don't redirect and show and error
            alert.error(errorMessage(error), {
              timeout: 5000,
              width: [300, 500],
            });
          }
        })
        .catch((error) => {
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length &&
            error.graphQLErrors[0].status === 'UNAUTHORIZED'
          ) {
            this.setState({ processing: false, processingId: id });
            setBackUrl(`/booking/${id}/${restaurant.foreignId}/process`);
            showModal(<SignInModal />, { type: 'narrow' });
          } else {
            this.setState({ processing: false, processingId: null });
            alert.error(errorMessage(error), {
              timeout: 5000,
              width: [300, 500],
            });
          }
        });
    }

    async cancelBooking({ id }) {
      const { cancelBookingMutation, alert } = this.props;
      this.setState({ processing: true });
      const { data, error } = await cancelBookingMutation({
        variables: { id },
      }).catch(() => {
        alert.error('Unable to cancel this booking');
      });
      this.setState({ processing: false });
      const { cancelBooking } = data;
      if (error || cancelBooking.status !== 'Cancelled') {
        alert.error('Unable to cancel this booking');
      } else {
        Cookies.remove('UpcomingBookings');
        this.setState({ bookingStatus: cancelBooking.status });
        alert.success('Your booking was cancelled');
      }
    }

    render() {
      const { processing, bookingStatus, processingId } = this.state;
      return (
        <WrappedComponent
          handleBooking={this.handleBooking}
          processBooking={this.processBooking}
          cancelBooking={this.cancelBooking}
          isProcessing={processing}
          processingId={processingId}
          bookingStatus={bookingStatus}
          {...this.props}
        />
      );
    }
  }

  BookingComponent.propTypes = {
    restaurant: PropTypes.object,
    createBooking: PropTypes.func.isRequired,
    cancelBookingMutation: PropTypes.func.isRequired,
    showModal: PropTypes.func,
    setBackUrl: PropTypes.func,
    alert: PropTypes.shape(),
  };

  return BookingComponent;
};

export default flowRight(
  withAlert(),
  withBackUrl,
  withModal,
  graphql(CREATE_BOOKING, { name: 'createBooking' }),
  graphql(CANCEL_BOOKING, { name: 'cancelBookingMutation' }),
  withHandleBooking,
);
