import React from 'react';
import PropTypes from 'prop-types';
import { CardElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Box from '../../../atoms/Box/Box';

// see = https://stripe.com/docs/stripe-js/react#customization-and-styling
const CARD_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#99A3AB',
      color: '#99A3AB',
      fontWeight: 300,
      fontSize: '18px',
      '::placeholder': {
        color: '#CFD7E0',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const StyledCardElement = styled(Box)`
  background: ${themeGet('colors.white')};
  box-shadow: 0px 4px 4px rgba(196, 182, 163, 0.1);
  border-radius: 2px;
  height: 54px;
  margin: 6px 0 24px;
  padding: 16px 12px 12px 12px;
`;

const CardSection = ({ setFieldValue, type }) => (
  <>
    <Box as="label" color="grayscale.600">Credit or debit card</Box> {/*eslint-disable-line*/}
    <StyledCardElement m="15px 0">
      <CardElement
        onFocus={() =>
          setFieldValue(
            'payment',
            type === 'NewPaymentIntention' ? 'NewPaymentIntention' : 'NewCard'
          )
        }
        options={CARD_OPTIONS}
      />
    </StyledCardElement>
  </>
);

CardSection.propTypes = {
  setFieldValue: PropTypes.func,
  type: PropTypes.string,
};
CardSection.defaultProps = {
  setFieldValue: () => {},
  type: 'NewCard',
};

export default CardSection;
