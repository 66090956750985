import React, { PureComponent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactMapGL, { FullscreenControl, Marker, NavigationControl } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import styled from 'styled-components';
import UserPin from '@firsttable/web-components/organisms/Restaurant/Map/UserPin';
import Box from '@firsttable/web-components/atoms/Box';
import RestaurantPin from '@firsttable/web-components/organisms/Restaurant/Map/RestaurantPin';
import RestaurantListItemMobile from '@firsttable/web-components/organisms/Restaurant/Item/Mobile';
import Flex from '@firsttable/web-components/atoms/Flex';
import { lineString } from '@turf/helpers';
import { bbox } from '@turf/turf';
import { Icon } from '@firsttable/web-components/atoms';
import RestaurantListItem from '../RestaurantListItem';
import { MapGLToken } from './config';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'mapbox-gl/dist/mapbox-gl.css';
import NavLink from '../../atoms/NavLink';

const fullscreenControlStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px',
};

const navStyle = {
  position: 'absolute',
  top: 36,
  left: 0,
  padding: '10px',
};

const StickyBox = styled(Box)`
  position: relative;
  max-width: 100%;
  background-color: #fff;
`;

const RestaurantWrapper = styled(Box)`
  z-index: 10;
  position: absolute;
  bottom: 32px;
  left: 32px;
  right: 32px;
  height: 1px;
  align-items: flex-end;
`;

const RestaurantMobileWrapper = styled(Box)`
  z-index: 10;
  position: absolute;
  bottom: ${({ bottomspace }) => bottomspace}px;
  left: 16px;
  right: 16px;
`;

const StyledCloseButton = styled(Box)`
  z-index: 10;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

class Markers extends PureComponent {
  render() {
    const { positions, setState, state } = this.props;
    return positions.map((restaurant) => (
      <Marker
        key={`marker-${restaurant.foreignId}`}
        longitude={restaurant.lng}
        latitude={restaurant.lat}
        offsetLeft={-16}
        offsetTop={-50}
      >
        <RestaurantPin
          color={restaurant.status === 'On Hold' ? '#d7dbdE' : '#FF685D'}
          onClick={() => setState({ ...state, selectedRestaurantId: restaurant.foreignId })}
        />
      </Marker>
    ));
  }
}

Markers.propTypes = {
  positions: PropTypes.array,
  setState: PropTypes.func,
  state: PropTypes.object,
};

const getRestaurantPositions = (restaurants) => {
  const positions = [];
  restaurants.map((node) => {
    if (node.lat && node.lng) {
      positions.push({
        id: node.id,
        foreignId: node.foreignId,
        lat: parseFloat(node.lat),
        lng: parseFloat(node.lng),
        status: node.status,
      });
    }
    return true;
  });
  return positions;
};

const getActiveRestaurants = (restaurants, selectedRestaurantId) => {
  if (selectedRestaurantId) {
    return restaurants.find((node) => node.foreignId === selectedRestaurantId);
  }
  return {};
};

const RestaurantMap = ({
  selectedDate, session, userLng, userLat, restaurants, bottomspace,
}) => {
  const [state, setState] = useState({
    selectedRestaurantId: null,
    viewport: {
      width: 400,
      height: 400,
    },
  });

  useEffect(() => {
    const { viewport } = state;
    const positions = [];

    if (typeof window !== 'undefined') {
      if (restaurants.length === 0) {
        return;
      }
      if (restaurants.length < 2) {
        setState({
          viewport: {
            ...viewport,
            longitude: parseFloat(restaurants[0].lng),
            latitude: parseFloat(restaurants[0].lat),
            zoom: 14,
          },
          selectedRestaurantId: restaurants[0].foreignId,
        });
      } else {
        restaurants.map((node) => {
          if (node.lat && node.lng) {
            positions.push([
              parseFloat(node.lng),
              parseFloat(node.lat),
            ]);
            return true;
          }
          return false;
        });
        if (userLat && userLng) {
          positions.push([
            parseFloat(userLng),
            parseFloat(userLat),
          ]);
        }
        const [minLng, minLat, maxLng, maxLat] = bbox(lineString(positions));
        const { longitude, latitude, zoom } = new WebMercatorViewport(viewport)
          .fitBounds([[minLng, minLat], [maxLng, maxLat]]);

        const newViewport = {
          ...viewport,
          longitude,
          latitude,
          zoom,
        };

        setState({ ...state, viewport: newViewport });
      }
    }
  }, []);

  const positions = getRestaurantPositions(restaurants);
  const selectedRestaurant = getActiveRestaurants(restaurants, state.selectedRestaurantId);
  return (
    <>
      <Flex height="100%" width="100%" justifyContent="center" position="relative">
        <ReactMapGL
          {...state.viewport}
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/mapbox/light-v9"
          onViewportChange={(viewport) => setState({ ...state, viewport })}
          mapboxApiAccessToken={MapGLToken}
        >
          <Box className="fullscreen" style={fullscreenControlStyle}>
            <FullscreenControl />
          </Box>
          <Box style={navStyle}>
            <NavigationControl onViewportChange={(viewport) => setState({ ...state, viewport })} />
          </Box>
          {userLng && userLat ? (
            <Marker
              longitude={userLng}
              latitude={userLat}
              offsetLeft={-16}
              offsetTop={-50}
            >
              <UserPin />
            </Marker>
          ) : null}
          <Markers positions={positions} setState={setState} state={state} />
        </ReactMapGL>
        {(state.selectedRestaurantId && selectedRestaurant) && (
          <>
            <RestaurantWrapper display={['none', 'flex']}>
              <StickyBox mx="auto" p={30}>
                <RestaurantListItem
                  restaurant={selectedRestaurant}
                  availabilitySession={session}
                  selectedDate={selectedDate}
                  restaurantSort={false}
                  allowFiltering={false}
                  mb={0}
                />
                <StyledCloseButton
                  width={30}
                  height={30}
                  right={0}
                  top={0}
                  role="button"
                  onClick={() => setState({ ...state, selectedRestaurantId: null })}
                >
                  <Icon name="nav-close" />
                </StyledCloseButton>
              </StickyBox>
            </RestaurantWrapper>
            <RestaurantMobileWrapper display={['block', 'none']} bottomspace={bottomspace}>
              <StickyBox mx="auto" p={10}>
                <RestaurantListItemMobile
                  restaurant={selectedRestaurant}
                  NavLinkComponent={NavLink}
                />
              </StickyBox>
            </RestaurantMobileWrapper>
          </>
        )}
      </Flex>
      {/* {(state.selectedRestaurantId && selectedRestaurant) && (
      <RestaurantMobileWrapper display={['flex', 'none']}>  */}
      {/*  <RestaurantListItemMobile restaurant={selectedRestaurant} /> */}
      {/* </RestaurantMobileWrapper>)} */}
    </>
  );
};

RestaurantMap.propTypes = {
  selectedDate: PropTypes.string,
  session: PropTypes.string.isRequired,
  userLat: PropTypes.number,
  userLng: PropTypes.number,
  restaurants: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  bottomspace: PropTypes.number,
};

RestaurantMap.defaultProps = {
  userLat: 0,
  userLng: 0,
  restaurants: {},
  selectedDate: '',
  bottomspace: 48,
};

export default RestaurantMap;
