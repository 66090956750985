import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';

const StyledReactModal = styled(ReactModal)`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: ${({ isLightBox, background }) => {
    if (background) return background;
    return isLightBox ? '#000' : '#fff';
  }};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  :focus {
    outline: 0;
  }
`;

const ModalComponent = ({
  children,
  isLightBox,
  isOpen,
  contentLabel,
  handleCloseModal,
  background,
}) => {
  let paddingRight = 0;
  if (typeof window !== 'undefined') {
    paddingRight = window.innerWidth - document.body.clientWidth;
  }
  return (
    <StyledReactModal
      isOpen={isOpen}
      overlayClassName="modal"
      contentLabel={contentLabel}
      onRequestClose={handleCloseModal}
      isLightBox={isLightBox}
      background={background}
      id="ReactModal"
    >
      {children}
      <Helmet>
        <style>
          {`body { overflow: hidden; padding-right: ${paddingRight}px; }`}
        </style>
      </Helmet>
    </StyledReactModal>
  );
};

ModalComponent.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  contentLabel: PropTypes.string,
  isLightBox: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  background: PropTypes.string,
};

ModalComponent.defaultProps = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  contentLabel: '',
  handleCloseModal: null,
  background: null,
  isLightBox: false,
};

export default ModalComponent;
