// borrowed from https://codepen.io/ekrof/pen/yOrPwv/
import React, { LabelHTMLAttributes, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import { themeGet } from '@styled-system/theme-get';
// @todo next-dev css
// import './modal.scss';

import { slugify } from '@firsttable/functions';
import { City, GroupRegionType } from '@firsttable/types';
import {
  Title,
  Row,
  Col,
  Icon,
  Button,
  BodyTitle,
  Link,
  Box,
  Container,
  Text,
} from '../../atoms';
import { BoxProps } from '../../atoms/Box/Box';

type RegionFilterProps = {
  heights: any; // todo not sure how this TS is best specified. Workaround to use any
};
const desktop: any = '1161px';
const RegionFilter = styled(Box)<RegionFilterProps>`
  display: flex;
  align-items: center;
  ${media.lessThan(desktop)`
    justify-content: center;
    height: ${({ heights }: any) => heights.mobile};
  `};
  ${media.greaterThan(desktop)`
    padding: 0 45px;
    height: ${({ heights }: any) => heights.desktop};
    border-left: 1px solid ${themeGet('colors.grayscale.300')};
    border-right: 1px solid ${themeGet('colors.grayscale.300')};
  `};
`;

const RegionList = styled.ul<BoxProps>`
  padding: 0;
  list-style: none;
  ${media.greaterThan(bp.s)`
    margin: 0 80px;
  `};
  column-count: 2;
  column-gap: 18%;
  margin: 5px 0;
  li {
    a {
      color: ${themeGet('colors.black')};
    }
  }
`;

const StyledCloseButton = styled.label`
  //z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  right: 70px;
  top: 70px;
  ${media.lessThan(bp.s)`
    right: 15px;
    top: 15px;
  `};
`;

const RegionsBtn = styled(Button)`
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  outline: none;
  user-select: none;
  transition: 0.1s ease;
  border-radius: 2px;
  letter-spacing: -0.5px;
  color: ${themeGet('colors.brand.blue')};
  background-color: ${themeGet('colors.white')};
  padding: 8px 16px;
  cursor: pointer;
  font-size: ${themeGet('fontSizes.xs')};
  font-weight: 700;
  border: 1px solid ${themeGet('colors.grayscale.400')};
`;

const RegionsWrapper = ({
  useButton,
  ...props
}: { useButton: boolean; kind?: string } & BoxProps &
  LabelHTMLAttributes<{}>) => {
  if (useButton) {
    return <RegionsBtn {...props} />;
  }
  return <RegionFilter {...props} />;
};

RegionsWrapper.propTypes = {
  // eslint-disable-next-line react/require-default-props
  useButton: PropTypes.bool,
};
type RegionsModalProps = {
  arrow?: boolean;
  useButton?: boolean;
  children: React.ReactNode;
  groupedRegions: GroupRegionType;
  regionsList?: any;
  modalNumber?: number | null;
  NavLinkComponent: React.ReactNode;
  heights?: any;
};

const RegionsModal = ({
  useButton = false,
  children,
  groupedRegions,
  regionsList,
  modalNumber,
  NavLinkComponent,
  ...props
}: RegionsModalProps & BoxProps) => {
  const [modalOpen, setModal] = useState(false);
  // handle body overflow hidden when open
  useEffect(() => {
    const body = document.querySelector('body');
    if (modalOpen && body) {
      body.classList.add('modal-open');
    } else if (!modalOpen && body) {
      body.classList.remove('modal-open');
    }
  }, [modalOpen]);
  const modalToggleNumber = modalNumber || (useButton ? '1' : '2');
  const id = `modal-toggle-${modalToggleNumber}`;
  const hasGroupRegions = !!groupedRegions?.edges.length;
  return (
    <Box {...props}>
      <RegionsWrapper
        className="modal-toggle-btn"
        as="label"
        kind="outline"
        htmlFor={id}
        useButton={useButton}
        {...props}
      >
        {children}
      </RegionsWrapper>
      <input
        id={id}
        className="modal-checkbox"
        type="checkbox"
        onClick={(e) => setModal(e.currentTarget.checked)}
      />
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <div className="modal-content">
        <StyledCloseButton role="button" className="modal-close" htmlFor={id}>
          <Icon name="nav-close" color="brand.blue" />
        </StyledCloseButton>
        <Container overflow="hidden" py={['30px', '66px', '100px']}>
          <Row>
            <Col>
              <Title
                as="h1"
                mb={['30px', '66px', '80px']}
                textAlign={[null, 'center']}
              >
                Find your location
              </Title>
              {hasGroupRegions ? (
                groupedRegions.edges.map(
                  ({ node: { id: regionId, title, cities } }) => (
                    <Box mt="40px" mb="20px" key={regionId}>
                      <BodyTitle
                        color="black"
                        textAlign="center"
                        size="l"
                        mb={['m', 'l', 69]}
                      >
                        {title}
                      </BodyTitle>
                      <RegionList mb={['7px', '20px']}>
                        {cities.map(({ id: cityId, menuTitle, slug }) => (
                          <Box
                            as="li"
                            width="100%"
                            mb={['14px', '20px']}
                            position="relative"
                            display="inline-block"
                            key={cityId}
                          >
                            <Link NavComponent={NavLinkComponent} to={slug}>
                              <Text fontSize="l" as="span">
                                {menuTitle}
                              </Text>
                            </Link>
                          </Box>
                        ))}
                      </RegionList>
                    </Box>
                  )
                )
              ) : (
                <RegionList mb={['7px', '20px']}>
                  {regionsList.regionLinks.map((region: City) => (
                    <Box
                      as="li"
                      width="100%"
                      mb={['14px', '20px']}
                      key={region.title}
                    >
                      <Link
                        NavComponent={NavLinkComponent}
                        to={
                          region.slug
                            ? region.slug
                            : `/${slugify(`${region.title}`)}/`
                        }
                      >
                        {region.title}
                      </Link>
                    </Box>
                  ))}
                </RegionList>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Box>
  );
};

export default RegionsModal;
