import React, { Component, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import Button from '@firsttable/web-components/atoms/Button';
import { createFacebookMember as CREATE_FB_QUERY } from '@firsttable/graphql-queries/src';
import {
  errorMessage,
  loggedInStatusEvent,
} from '@firsttable/functions';
import { useAlert } from 'react-alert';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import AuthContext from '../../../context/AuthContext';
import useBackUrl from '../../../hooks/backUrl';

class FacebookLoginButton extends Component {
  static initializeFacebookSDK() {
    // eslint-disable-next-line func-names
    window.fbAsyncInit = function () {
      FB.init({ // eslint-disable-line
        appId: process.env.GATSBY_FACEBOOK_ID,
        cookie: true, // enable cookies to allow the server to access the session
        version: 'v6.0',
      });
    };
    // Load the SDK asynchronously
    // eslint-disable-next-line func-names
    (function (d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      // eslint-disable-next-line prefer-const
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    FacebookLoginButton.initializeFacebookSDK();
  }

  facebookCallback = async (facebookResponse) => {
    const {
      createFacebookMember,
      signUpData,
      handlePostLogin,
      handleLogin,
      alert,
      onAfterLogin,
      pathname,
    } = this.props;
    if (facebookResponse.status === 'connected') {
      this.setState({ isLoading: true });
      const facebookToken = facebookResponse.authResponse.accessToken;
      const region = +signUpData.cityId || 0;
      try {
        const { data, error } = await createFacebookMember({
          variables: {
            token: facebookToken,
            ...signUpData,
            cityId: region,
          },
        });
        if (data) {
          handleLogin({
            member: data.createFacebookMember.member,
            token: data.createFacebookMember.token,
            isLoggedIn: true,
          });

          loggedInStatusEvent({
            event: 'login',
            userId: data.createFacebookMember.member.id,
            category: 'login - facebook',
            loggedInStatus: 'TRUE',
          });

          // generic processing of login
          handlePostLogin({
            createFacebookMember: data.createFacebookMember,
            isSocialLogin: true,
            isLoggedIn: true,
            onAfterLogin,
            ...data,
          }).then((d) => {
            if (
              d &&
              !d.redirected &&
              (pathname.includes('auth') || pathname.includes('sign-up'))
            ) {
              navigate('/');
            }
          });
        }
        if (error) {
          this.setState({ isLoading: false });
          alert.error(errorMessage(error));
        }
      } catch (e) {
        this.setState({ isLoading: false });
        alert.error(errorMessage(e));
      }
    } else {
      this.setState({ isLoading: false });
      alert.error('Unable to authorize with Facebook.');
    }
  };

  handleFBLogin = () => {
    const { FB } = window;
    if (FB) {
      FB.login((response) => { // eslint-disable-line
          this.facebookCallback(response);
        },
        { scope: 'public_profile,email' },
      );
    }
  };

  render() {
    const { isLoading } = this.state;
    return (
      <Button
        size="l"
        isLoading={isLoading}
        onClick={this.handleFBLogin}
        data-testid="fb-login"
        wide
        kind="facebook"
      />
    );
  }
}

FacebookLoginButton.propTypes = {
  createFacebookMember: PropTypes.func.isRequired,
  signUpData: PropTypes.shape(),
  handlePostLogin: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  alert: PropTypes.shape(),
  onAfterLogin: PropTypes.func,
  pathname: PropTypes.string,
};
FacebookLoginButton.defaultProps = {
  onAfterLogin: null,
};

const FacebookLoginWrapper = ({ backUrl: authBackUrl, ...props }) => {
  const { setUser, handlePostLogin, handleLogin } = useContext(AuthContext);
  const { pathname } = useLocation();
  const { setBackUrl } = useBackUrl();
  const alert = useAlert();
  useEffect(() => {
    if (authBackUrl) {
      setBackUrl(authBackUrl);
    }
    return () => {};
  }, [authBackUrl, setBackUrl]);

  return (
    <FacebookLoginButton
      setUser={setUser}
      handleLogin={handleLogin}
      handlePostLogin={handlePostLogin}
      alert={alert}
      pathname={pathname}
      {...props}
    />
  );
};

FacebookLoginWrapper.propTypes = {
  backUrl: PropTypes.string,
};

FacebookLoginWrapper.defaultProps = {
  backUrl: null,
};

export default compose(
  graphql(CREATE_FB_QUERY, { name: 'createFacebookMember' }),
)(FacebookLoginWrapper);
