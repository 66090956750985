import React from 'react';
import PropTypes from 'prop-types';

const Pin = ({ onClick, style }) => (
  <svg onClick={onClick} style={style} width="36" height="50" viewBox="0 0 36 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="18" cy="46" rx="16" ry="4" fill="#272B2E" fillOpacity="0.12" />
    <path d="M36 19.1667C36 29.7521 18 46 18 46C18 46 0 29.7521 0 19.1667C0 8.58121 8.05887 0 18 0C27.9411 0 36 8.58121 36 19.1667Z" fill={style.fill} />
    <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="46">
      <path d="M36 19.1667C36 29.7521 18 46 18 46C18 46 0 29.7521 0 19.1667C0 8.58121 8.05887 0 18 0C27.9411 0 36 8.58121 36 19.1667Z" fill={style.innerFill || '#D6BB96'} />
    </mask>
    <g mask="url(#mask0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5242 40.3892C17.5134 39.7739 28.4224 31.4077 22.54 16.1519L25 12.5835L22.0054 15.2905L23.8235 10L20.7219 13.5679C19.2246 11.8457 15.032 8.96631 10.2407 11.2305C7.24805 12.998 5.90674 16.5957 5.15332 18.6167L5.15088 18.6235C4.90918 19.2715 4.72827 19.7568 4.57227 19.9658C4.49487 20.0698 4.38013 20.2661 4.22412 20.5332C3.08813 22.479 -0.231445 28.1646 -7.19263 28.9473C-11.1733 28.9473 -13.9304 25.9941 -15 24.7642C-13.5027 29.9722 -7.68457 40.3892 3.60962 40.3892L3.7561 41.2261L3.75903 41.2432C4.14624 43.4507 4.79541 47.1538 5.10693 49.6626L6.60425 40.7583H8.63647L10.0269 49.1704L11.5242 40.3892ZM-0.0439453 32.1045C2.52856 33.6133 5.47852 33.98 8.34668 33.6768C6.93164 34.4155 5.09155 35.0781 2.64233 35.7041C10.4189 38.0757 15.6904 36.1592 20.3442 28.3062C20.3442 28.3062 20.2573 28.3477 20.1023 28.4097C19.3723 28.7007 17.1292 29.4326 15.3069 28.3052C12.6555 26.666 11.8533 24.6499 11.8533 24.6499C9.55054 29.4067 3.92627 31.7808 -0.0439453 32.1045ZM15.4812 16.52L17.9412 13.0752C17.5134 13.1572 16.6362 13.4688 16.123 14.0596C15.6096 14.6499 15.4456 15.9458 15.4812 16.52ZM10.5615 49.5396C10.647 49.5396 11.8806 49.6216 12.4866 49.6626L11.9519 51.1392L10.5615 49.5396ZM10.2405 49.6626L10.4546 52L11.5239 51.2622L10.2405 49.6626ZM8.8501 51.2622L9.91968 49.6626L10.0266 51.877L8.8501 51.2622ZM7.24585 49.9087L6.81812 51.1392L5.21387 49.9087H7.24585ZM6.60425 51.2622L5.21387 50.2778L5.3208 52L6.60425 51.2622ZM5 50.1548V52L3.93042 51.6313L5 50.1548Z" fill="white" />
    </g>
  </svg>
);

Pin.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.shape({
    fill: PropTypes.string,
    innerFill: PropTypes.string,
  }),
};
Pin.defaultProps = {
  onClick: () => {},
  style: {
    cursor: 'pointer',
    fill: '#FF685D',
    stroke: 'none',
  },
};

export default Pin;
