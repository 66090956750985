import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  availabilityDaysToShow,
  dayOfMonth,
  dayOfWeek,
  getCountDownString,
  timeOfBooking,
} from '@firsttable/functions';
import { themeGet } from '@styled-system/theme-get';
import { Alert, Box, Dots, Flex, Icon } from '../../atoms';
import { StyledAvailability, WeekDay } from '../../molecules/Availability';

const CountdownMessage = styled(Alert)`
  background: none;
  color: ${themeGet('colors.brand.red')};
  padding: 5px 0 0;
  margin: -10px 0;
  font-weight: 900;
`;

const Day = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  @media (max-width: 519px) {
    font-size: calc(12px + ((1vw - 3.2px) * 1.5));
  }
`;

const Availability = ({
  availability,
  processBooking,
  selectedDate,
  isProcessing,
  processingId,
  testid,
  // hasLastTable,
  // currentSession,
  // setSession,
}) => {
  const daysToShow = availabilityDaysToShow(
    availability,
    +(process.env.GATSBY_SITE_ID || process.env.NEXT_PUBLIC_SITE_ID)
  );
  const showCutOff = daysToShow.filter((key) => {
    const day = availability[key];
    const localCutOffDateTime = new Date(`${day.cutOffTime} UTC`);
    const nowDateTime = new Date();
    const timeToCutOff = localCutOffDateTime.getTime() - nowDateTime.getTime();

    const msInHour = 1000 * 60 * 60;
    const hoursAway = timeToCutOff / msInHour;
    return (
      hoursAway < 4 && // show if less that 4 hours away
      hoursAway > 0 && // show only if today i.e. not negative hours away
      day.cutOffTime &&
      day.available
    );
  });

  const bookingText = (day) => {
    if (!day.open) {
      return 'Closed';
    }
    if (!day.available) {
      return '–';
    }
    return timeOfBooking(day.time);
  };

  return (
    <>
      <Flex
        overflow="auto"
        textAlign="center"
        justifyContent="space-between"
        data-testid={testid}
      >
        {daysToShow.map((key, index) => {
          const day = availability[key];

          const showArrow = showCutOff.includes(day.date);
          const isDayAvailable = () => {
            if (!day.available || !day.open) return null;
            return 1;
          };

          return (
            <Day key={day.id}>
              <StyledAvailability
                available={isDayAvailable()}
                onClick={isDayAvailable() ? () => processBooking(day) : null}
                selected={false}
                className={isProcessing ? 'is-processing' : null}
                processing={isProcessing && processingId === day.id ? 1 : 0}
                position={index}
              >
                <WeekDay fontSize="0.7375em" available={isDayAvailable()}>
                  {dayOfWeek(day.date)}
                </WeekDay>
                <Box>
                  {isDayAvailable() ? (
                    <strong>{dayOfMonth(day.date)}</strong>
                  ) : (
                    dayOfMonth(day.date)
                  )}
                </Box>
                <Box fontSize={['0.8375em', '0.9375em']} lineHeight={1.3}>
                  {isProcessing && processingId === +day.id ? (
                    <Dots color="white" />
                  ) : (
                    bookingText(day)
                  )}
                </Box>
              </StyledAvailability>
              {showArrow && <Icon name="s-arrow-up" color="brand.red" />}
            </Day>
          );
        })}
      </Flex>
      {(() => {
        if (!showCutOff.length) return null;
        return (
          <Box p={['0 16px', 0]}>
            <CountdownMessage
              textAlign="left"
              type="error"
              fontSize="s"
              message={getCountDownString(availability[showCutOff].cutOffTime)}
            />
          </Box>
        );
      })()}
    </>
  );
};

Availability.propTypes = {
  testid: PropTypes.string,
  selectedDate: PropTypes.string,
  availability: PropTypes.object,
  processBooking: PropTypes.func,
  processingId: PropTypes.number,
  isProcessing: PropTypes.bool,
};
Availability.defaultProps = {
  testid: null,
  selectedDate: '',
  availability: {},
  processingId: null,
  isProcessing: false,
  processBooking: null,
};

export default Availability;
