import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../atoms/Box/Box';
import Icon from '../../atoms/Icon/Icon';
import Text from '../../atoms/Text/Text';
import BodyTitle from '../../atoms/BodyTitle';

const HomeInfo = ({ icon, title, message }) => (
  <Box textAlign="center">
    <Icon name={`xl-${icon}`} mb="25px" />
    <BodyTitle size="m" as="h2">
      {title}
    </BodyTitle>
    <Text maxWidth="350px" m="0 auto">
      {message}
    </Text>
  </Box>
);

HomeInfo.propTypes = {
  icon: PropTypes.node,
  message: PropTypes.string,
  title: PropTypes.string,
};
HomeInfo.defaultProps = {
  icon: PropTypes.node,
  message: PropTypes.string,
  title: PropTypes.string,
};

export default HomeInfo;
