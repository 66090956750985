import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import * as theme from './index';

const BaseStyles = styled.div`
  font-family: ${themeGet('fonts.body')};
  color: ${themeGet('colors.black')};
  line-height: ${themeGet('lineHeights.m')};
`;

const ModalTheme = ({ children }: { children: any }) => (
  <ThemeProvider theme={theme}>
    <BaseStyles>
      {children}
    </BaseStyles>
  </ThemeProvider>
);

ModalTheme.propTypes = {
  children: PropTypes.node.isRequired,
};
ModalTheme.defaultProps = {};

export default ModalTheme;
