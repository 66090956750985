import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import moment from 'moment';
import {
  dayOfWeek,
  dayAndTimeLong,
  availabilityDaysToShow,
  sessionLabel,
} from '@firsttable/functions';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import { Restaurant } from '@firsttable/types';
import {
  Box,
  Flex,
  Button,
  Title,
  Text,
  Divider,
  Dots,
  BodyTitle,
  Switch,
  SwitchBorder,
} from '../../atoms';
import Availability from '../Availability';

const LastTableWrapper = styled(Flex)`
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.open ? '1' : '0')};
  transition: visibility 0s 0.3s, opacity 0.3s linear;
  margin: 10px 0;
`;

const AvailabilityTableItem = styled(Flex)`
  border: 1px solid ${themeGet('colors.grayscale.300')};
  margin-bottom: -1px;
  background: #fff;
  transition: 0.1s ease;
  cursor: default;
  ${({ available }) =>
    available
      ? css`
          cursor: pointer;
          &:hover {
            padding-left: 5px;
            transform: scale(1.05);
            box-shadow: 0 4px 10px rgba(21, 26, 31, 0.06);
            border-color: transparent;
          }
        `
      : null};
`;

type ButtonBoxPropsType = { available: any };
const ButtonBox = styled(Box)<ButtonBoxPropsType>`
  transition: 0.1s ease;
  ${({ available }) =>
    available
      ? css`
          ${AvailabilityTableItem}:hover & {
            background: ${themeGet('colors.brand.red')};
            color: #fff;
            border-radius: 2px;
            width: 36.5%;
          }
        `
      : null};
`;

type RestaurantAvailabilityType = {
  availability: any;
  isLoading: boolean;
  isProcessing?: boolean;
  processBooking: (day: any, type: any) => void;
  processingId?: number;
  availabilitySession: any;
  restaurantStatus: any;
  restaurant: Restaurant;
  AvailabilitySessionBtn: any;
  NavButtonComponent: any;
  showLastTableLayout: boolean;
  setSession: (s: string) => void;
};

const RestaurantAvailability = ({
  availability,
  isLoading,
  isProcessing,
  processBooking,
  processingId,
  availabilitySession,
  restaurant,
  AvailabilitySessionBtn,
  NavButtonComponent,
  showLastTableLayout,
  setSession,
}: RestaurantAvailabilityType) => {
  const { availabilitySessions } = restaurant;
  const hasLastTableAndFirstTable =
    showLastTableLayout || availabilitySessions?.includes('dinner2') || false;
  const currentAvailability: any = Object.keys(availability).length
    ? availability[availabilitySession]
    : {};

  const availabilityIsLoaded: boolean =
    currentAvailability && Object.keys(currentAvailability).length > 0;

  let daysToShow = [];
  const siteId = +(process?.env?.GATSBY_SITE_ID ?? 1);
  if (siteId && availabilityIsLoaded) {
    daysToShow = availabilityDaysToShow(currentAvailability, siteId);
  }
  const lastTableActive = availabilitySession === 'dinner2';
  const firstTableActive = availabilitySession === 'dinner';

  return (
    <Box id="RestaurantAvailability">
      {availabilitySessions && (
        <Flex
          mb={!hasLastTableAndFirstTable ? '25px' : ''}
          display={['none', null, 'flex']}
        >
          {availabilitySessions
            .filter((s: string) => s !== 'dinner2')
            .map((s: string) => (
              <AvailabilitySessionBtn
                session={availabilitySession}
                showActive={s === 'dinner' && availabilitySession === 'dinner2'}
                key={s}
                label={s}
              />
            ))}
        </Flex>
      )}

      {hasLastTableAndFirstTable && (
        <LastTableWrapper
          open={availabilitySession.includes('dinner', 'dinner2')}
          display={['none', 'none', 'flex']}
        >
          <SwitchBorder
            fontSize={['xxs', 'xs']}
            alignSelf="center"
            width="100%"
          >
            <Switch
              active={firstTableActive}
              orientation="left"
              onClick={() => {
                if (availabilitySessions?.includes('dinner')) {
                  setSession('dinner');
                }
              }}
              width="50%"
            >
              First Table
            </Switch>
            <Switch
              active={lastTableActive}
              orientation="right"
              onClick={() => {
                setSession('dinner2');
              }}
              width="50%"
            >
              Last Table
            </Switch>
          </SwitchBorder>
        </LastTableWrapper>
      )}
      <Box display={['none', null, 'block']}>
        <Box className={isProcessing ? 'is-processing' : ''}>
          {isLoading ? (
            <Box flex={1} className="pulse-animation" width="100%">
              <Box
                bg="grayscale.300"
                width="100%"
                height="28px"
                mb="15px"
                mt="10px"
              />
              <Box
                bg="grayscale.300"
                width="100%"
                height="28px"
                mb="15px"
                mt="10px"
              />
              <Box
                bg="grayscale.300"
                width="100%"
                height="28px"
                mb="15px"
                mt="10px"
              />
            </Box>
          ) : (
            availabilityIsLoaded &&
            daysToShow.map((key: any) => {
              const day = currentAvailability[key];
              // const isProcessing = false;
              const isDayAvailable = () => {
                if (!day.available || !day.open) return null;
                return 1;
              };
              const buttonText = () => {
                if (!day.available) return '–';
                if (!day.open) return 'Closed';
                return 'Book';
              };
              return (
                <AvailabilityTableItem
                  key={day.id}
                  available={isDayAvailable()}
                  onClick={
                    isDayAvailable() ? () => processBooking(day, 'hi') : null
                  }
                >
                  <Flex alignItems="center" flex={1}>
                    <Box
                      textAlign="center"
                      width="62px"
                      fontSize="xxs"
                      style={{ textTransform: 'uppercase' }}
                      fontWeight={600}
                      color={!isDayAvailable() ? 'grayscale.600' : ''}
                    >
                      {dayOfWeek(day.date)}
                    </Box>
                    <Divider width="1px" height="28px" my="9px" />
                    <Box
                      fontSize="xs"
                      lineHeight={1.3}
                      color={!isDayAvailable() ? 'grayscale.600' : ''}
                      flex={1}
                      textAlign="left"
                      style={{ paddingLeft: '25px' }}
                    >
                      {day.available
                        ? dayAndTimeLong(day.date, day.time)
                        : moment(day.date).format('D MMM')}
                    </Box>
                  </Flex>
                  <ButtonBox
                    available={isDayAvailable() ? 'true' : null}
                    borderLeft="1px solid"
                    borderColor="grayscale.300"
                    width={[1 / 3]}
                    fontSize="xs"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color={isDayAvailable() ? 'brand.blue' : 'grayscale.600'}
                    fontWeight={600}
                  >
                    {isProcessing && processingId === +day.id ? (
                      <Dots />
                    ) : (
                      buttonText()
                    )}
                  </ButtonBox>
                </AvailabilityTableItem>
              );
            })
          )}
        </Box>
      </Box>
      <Box maxWidth={600} display={[null, null, 'none']}>
        {isLoading ? (
          <Box className="pulse-animation">
            <Box bg="grayscale.300" width="100%" height="10px" mb="s" />
            <Box bg="grayscale.300" width="100%" height="20px" />
          </Box>
        ) : (
          Object.keys(availability)
            .sort((a, b) => {
              if (
                a.toLowerCase() === 'breakfast' &&
                b.toLowerCase() === 'lunch'
              ) {
                return -1;
              }
              if (a.toLowerCase() === 'lunch' && b.toLowerCase() === 'dinner') {
                return -1;
              }
              return 0;
            })
            .map((session: any) => (
              <Box mt={[20, 30]} key={session}>
                <BodyTitle
                  fontWeight={600}
                  mb="0.3em"
                  size="xs"
                  color="brand.blue"
                >
                  {sessionLabel(session)}
                </BodyTitle>
                <Box m={['0 -16px', '0', '0']}>
                  <Availability
                    key={session}
                    availability={availability[session]}
                    isProcessing={isProcessing}
                    processBooking={processBooking}
                    processingId={processingId}
                    testid="availability__detail"
                  />
                </Box>
              </Box>
            ))
        )}
      </Box>
    </Box>
  );
};

RestaurantAvailability.propTypes = {
  availability: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isLoading: PropTypes.bool,
  isProcessing: PropTypes.bool,
  processingId: PropTypes.number,
  availabilitySession: PropTypes.string,
  restaurantStatus: PropTypes.any,
  restaurant: PropTypes.shape({
    availabilitySessions: PropTypes.array,
    status: PropTypes.string,
    onHoldText: PropTypes.string,
    title: PropTypes.string,
    breadcrumbs: PropTypes.object,
    regionId: PropTypes.number,
  }),
  AvailabilitySessionBtn: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
  processBooking: PropTypes.func.isRequired,
  NavButtonComponent: PropTypes.any.isRequired,
};
RestaurantAvailability.defaultProps = {
  availability: {},
  availabilitySession: 'dinner',
  isLoading: true,
  isProcessing: false,
  processingId: null,
  restaurantStatus: {},
  restaurant: {},
};

export default RestaurantAvailability;
