import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import SignUpForm from '../Forms/SignUpForm';
import AuthContext from '../../../context/AuthContext';
import AuthLayout from './AuthLayout';

const SignUpLayout = ({ children, ...props }) => {
  const { setUser } = useContext(AuthContext);
  const { siteConfig } = useStaticQuery(graphql`
    query signUpCurrency {
      siteConfig {
        currency
      }
    }
  `);
  return (
    <AuthLayout signUp>
      <SignUpForm setUser={setUser} siteConfig={siteConfig} {...props}>
        {children}
      </SignUpForm>
    </AuthLayout>
  );
};

SignUpLayout.propTypes = {
  children: PropTypes.node,
};
SignUpLayout.defaultProps = {};

export default SignUpLayout;
