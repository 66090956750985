import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import media from 'styled-media-query';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import moment from 'moment';
import { numericToWord } from '@firsttable/functions';
import Flex from '../../atoms/Flex';
import Box from '../../atoms/Box';
import Icon from '../../atoms/Icon';
import { BodyTitle, Text } from '../../atoms';

const RateNum = styled.label`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, max-content));
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ height }) => height};
  border-radius: 2px;
  border: 1px solid ${themeGet('colors.grayscale.300')};
  transition: 0.1s ease;
  background: white;
  padding: 0 10%;
  cursor: pointer;
  ${({ isDisabled }) =>
    !isDisabled
      ? css`
          color: ${themeGet('colors.gold.800')};
        `
      : css`
          color: ${themeGet('colors.grayscale.600')};
        `}
  ${media.lessThan(bp.s)`
    height: 90px;
  `};
  ${media.greaterThan(bp.s)`
    height: 98px;
  `};
`;
const Wrap = styled(Box)`
  input:checked + label {
    border-color: ${themeGet('colors.grayscale.600')};
    color: ${themeGet('colors.brand.blue')};
  }
  &:last-child {
    margin-right: 0;
  }
`;

const LeaveTime = styled(Box)`
  font-size: 80%;
  text-align: center;
  line-height: clamp(1.2em, 2vw, 2em);
`;

const getLeaveTime = (leaveTime, table) => {
  // check if array
  if (Array.isArray(leaveTime) && !leaveTime.length) {
    return null;
  }
  // check if string
  if (!leaveTime) {
    return null;
  }

  const time =
    typeof leaveTime === 'object' && leaveTime
      ? leaveTime[table]
      : moment(leaveTime);

  return (
    <LeaveTime>
      Leave time: {moment(time, 'HH:mm:ss').format('h:mm A')}
    </LeaveTime>
  );
};

const BookingTable = ({
  name,
  options,
  leaveTime,
  maxWidth,
  height,
  onChange,
  onBlur,
  selected,
  allowedDiners,
  restaurant,
}) => {
  let numberOfDiners = '';
  allowedDiners.map((num, i) => {
    const length = allowedDiners.length - 1;
    numberOfDiners = `${numberOfDiners}${
      i === length && length > 1 ? ' or ' : ''
    }${numericToWord(num)}${i < length - 1 ? ', ' : ' '}`;
    return num;
  });
  return (
    <>
      <BodyTitle size="s" m="20px 0 0">
        How many people?
      </BodyTitle>
      <Text color="grayscale.600">
        Book a table for {numberOfDiners} people at {restaurant.title}
      </Text>
      <Flex width="100%" maxWidth={maxWidth} justifyContent="space-between">
        {options.map((opt) => {
          const id = `${name}-${opt.inputValue}`;
          const value = opt.inputValue;
          const isDisabled = allowedDiners.indexOf(+value) === -1;
          // const tableLeaveTime = leaveTime ? moment(lts[value], 'HH:mm:ss').format('h:mm A');
          // console.log(id, value, lts[value], moment(lts[value], 'HH:mm:ss').format('h:mm A'));
          return (
            <Wrap key={id} width="33.3333%" height="100%" mr="8px">
              <input
                style={{ display: 'none' }}
                id={id}
                name={name}
                type="radio"
                value={value}
                checked={selected === value}
                disabled={isDisabled}
                onChange={(e) => onChange(name, +e.target.value)}
                onBlur={onBlur}
              />
              <RateNum height={height} htmlFor={id} isDisabled={isDisabled}>
                <Box>
                  {Array.apply(0, Array(Number(value))).map((x, i) => (
                    <Box
                      width={['17px', '25px']}
                      display="inline-block"
                      mx="2px"
                      key={`people${i}`}
                    >
                      <Icon
                        width="100%"
                        height="1.6em"
                        viewBox="0 0 25 40"
                        fontSize="23px"
                        name="chair"
                        color="inherit"
                        m="0 2px"
                      />
                    </Box>
                  ))}
                </Box>
                {getLeaveTime(leaveTime, value)}
              </RateNum>
            </Wrap>
          );
        })}
      </Flex>
    </>
  );
};
BookingTable.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  leaveTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selected: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func.isRequired,
  allowedDiners: PropTypes.array,
  restaurant: PropTypes.shape().isRequired,
};
BookingTable.defaultProps = {
  name: 'people',
  options: [],
  leaveTime: null,
  height: null,
  maxWidth: null,
  selected: 2,
  onChange: () => {},
  allowedDiners: [2, 3, 4],
};

export default BookingTable;
