import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { enableAnalytics } from '../../../next-web/components/hocs/withTracking';
import Text from '../../atoms/Text';
import Box from '../../atoms/Box';
import Button from '../../atoms/Button';
import cookieImg from './cookie.jpg';
import { Link } from '../../atoms';

const CookieConsentWrapper = styled(Box)`
  pointer-events: none;
  z-index: 999;
  position: fixed;
`;
const CookieConsentInner = styled(Box)`
  position: relative;
  pointer-events: auto;
  margin: 0 auto;
  max-width: 850px;
  padding: 15px 20px 15px 120px;
  background: #fff;
  box-shadow: 0 0 5px rgba(39, 43, 46, 0.3);

  .cookieConsent {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
    }

    &-content {
      @media (min-width: 768px) {
        flex: 1;
        margin-right: 20px;
      }
    }
  }
`;
const Img = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  background: url('${cookieImg}') no-repeat center center / cover;
`;

class CookieConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    const { cookieName, debug } = this.props;
    if (Cookies.get(cookieName) === undefined || debug) {
      this.setState({ visible: true });
    }
  }

  handleClick = (handle) => {
    const { cookieName, expires } = this.props;
    Cookies.set(cookieName, handle, { expires });
    // Enable analytics if user accepts cookies
    if (handle) {
      enableAnalytics();
    }
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    const { NavLinkComponent } = this.props;
    if (!visible) {
      return null;
    }

    return (
      <CookieConsentWrapper bottom={[0, 20]} left={[0, 20]} right={[0, 20]}>
        <CookieConsentInner>
          <Img />
          <Box display={[null, 'flex']} alignItems={[null, 'center']}>
            <Box flex={[null, '1']} mr={[null, 20]}>
              <Text mb={[null, 0]} lineHeight={1.3} fontSize="s">
                First Table uses cookies for measurement, ads and optimisation.{' '}
                <br />
                To learn what information we collect and how it's processed, you
                can read our{' '}
                <Link
                  NavComponent={NavLinkComponent}
                  to="/privacy-policy/"
                  underline
                  color="brand.red"
                >
                  privacy policy
                </Link>{' '}
                here.
              </Text>
            </Box>
            {/* <Box display={['flex']} alignItems={[null, 'center']}> */}
            <Box width={[1, 'auto']} ml={[null, 20]} mb={[10, 0]} order={3}>
              <Button
                size="s"
                kind="normal"
                wide
                onClick={() => this.handleClick(true)}
              >
                Allow all
              </Button>
            </Box>
            <Box width={[1, 'auto']} order={2}>
              <Button
                size="s"
                kind="outline"
                wide
                onClick={() => this.handleClick(false)}
              >
                Disable cookies
              </Button>
            </Box>
            {/* </Box> */}
          </Box>
        </CookieConsentInner>
      </CookieConsentWrapper>
    );
  }
}

CookieConsent.propTypes = {
  debug: PropTypes.bool,
  cookieName: PropTypes.string,
  expires: PropTypes.number,
  NavLinkComponent: PropTypes.any.isRequired,
};
CookieConsent.defaultProps = {
  debug: false,
  cookieName: 'CookieConsentFT',
  expires: 365,
};

export default CookieConsent;
