import { isBrowser } from '@firsttable/functions';

const func = () => {};
const fbq = isBrowser ? window.fbq || func : {};
const dataLayer = isBrowser ? window.dataLayer || [] : [];

const ecomLayer = (data) => {
  // console.log(data);
  dataLayer.push(data);
};

export { fbq, dataLayer, ecomLayer };
