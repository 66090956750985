import React, { CSSProperties } from 'react';
// import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { awsImage, dayOfMonthShort, dayOfWeek } from '@firsttable/functions';
import Img from 'gatsby-image';
import { Availability } from '@firsttable/types';
import Box from '../../atoms/Box';
import { Flex, BodyTitle, Text, Divider, Link } from '../../atoms';
import PriceRating from '../../molecules/PriceRating';

const textOverflowHiddenStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export interface ListItemCardProps {
  item: any;
  subTitle?: string;
  width?: number;
  img?: { url: string; [key: string]: any };
  dataImg?: null;
  isLoading?: boolean;
  availability: Availability[];
  NavComponent: React.ReactNode;
}

const ListItemCard = ({
  item,
  subTitle,
  width = 285,
  img,
  dataImg,
  isLoading,
  availability,
  NavComponent,
}: ListItemCardProps) => {
  const imgHeight = 240;
  return (
    <Link NavComponent={NavComponent} to={item.slug}>
      <Box width={width} colors="card.s">
        <Box bg="#ebeff2" height={imgHeight}>
          {img && (
            <LazyLoadImage
              srcSet={`${awsImage(img.url, width, imgHeight)} 1x, ${awsImage(
                img.url,
                width * 2,
                imgHeight * 2
              )} 2x`}
            />
          )}
          {dataImg && <Img fixed={dataImg} />}
        </Box>
        <Box p="20px 25px">
          <Flex>
            <BodyTitle
              size="xs"
              mb={0}
              style={{ flex: 1, ...textOverflowHiddenStyles }}
            >
              {item.title}
            </BodyTitle>
            {item.price && (
              <Box ml="20px">
                <PriceRating rating={item.price} />
              </Box>
            )}
          </Flex>
          <Text
            fontSize="xs"
            lineHeight="xs"
            color="grayscale.600"
            style={{ ...textOverflowHiddenStyles }}
          >
            {subTitle || <span>&nbsp;</span>}
          </Text>
          {availability && (
            <Flex justifyContent="space-between">
              {isLoading ? (
                <Box
                  bg="grayscale.300"
                  width="100%"
                  height="24px"
                  mb="xs"
                  mt="xs"
                />
              ) : (
                Object.keys(availability)
                  .slice(0, 7)
                  .map((key: any) => {
                    const day = availability[key];
                    const dateKey = day.id || item.id;
                    return (
                      <Box key={dateKey} width="27px" textAlign="center">
                        <Text
                          color="grayscale.600"
                          mb="3px"
                          fontSize="xxs"
                          lineHeight="xxs"
                        >
                          {dayOfWeek(`${day.date}`)}
                        </Text>
                        <Divider />
                        <Text
                          mb={0}
                          fontSize="xs"
                          lineHeight="xs"
                          color={day.available ? 'black' : 'grayscale.600'}
                        >
                          <strong>{dayOfMonthShort(`${day.date}`)}</strong>
                        </Text>
                      </Box>
                    );
                  })
              )}
            </Flex>
          )}
        </Box>
      </Box>
    </Link>
  );
};

export default ListItemCard;
