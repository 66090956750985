import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { range } from '@firsttable/functions';
import moment from 'moment';
import Cookies from 'js-cookie';
import getFirebase from '../helpers/firebase';

const withFireBaseAvailability = (WrappedComponent) =>
  class FirebaseAvailability extends Component {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
      availabilitySession: PropTypes.string.isRequired,
      restaurant: PropTypes.object.isRequired,
      selectedDate: PropTypes.string,
      showAllAvailability: PropTypes.bool,
      restaurantAvailabilityRef: PropTypes.func,
      searchFilterState: PropTypes.any,
      allowFiltering: PropTypes.bool,
    };

    // eslint-disable-next-line react/static-property-placement
    static defaultProps = {
      showAllAvailability: false,
      restaurantAvailabilityRef: null,
      allowFiltering: true,
    };

    constructor(props) {
      super(props);
      this.setAvailability = this.setAvailability.bind(this);
      this.setCurrentSession = this.setCurrentSession.bind(this);
      this.getAvailabilityKey = this.getAvailabilityKey.bind(this);
      this.state = {
        availability: {},
        loading: true,
        upcomingBookings: [],
        currentSession: 'dinner', // used for session state change event handling, differs to availabilitySession passed in by props
        shouldShowRestaurant: true, // used for hiding a restaurant on certain search criteria
        forceDinnerSessionChange: false,
      };
    }

    componentDidMount() {
      if (typeof window !== 'undefined') {
        let upcomingBookings = Cookies.get('UpcomingBookings');
        upcomingBookings = upcomingBookings ? JSON.parse(upcomingBookings) : [];
        const { restaurant } = this.props;
        const id = restaurant.foreignId || restaurant.id;
        const filteredUpcomingBookings = [];
        if (Array.isArray(upcomingBookings)) {
          upcomingBookings.map(({ node }) => {
            if (node.restaurant.id === id) {
              filteredUpcomingBookings.push(node.availability.id);
            }
            return true;
          });
        }
        this.setState({
          loading: true,
          upcomingBookings: filteredUpcomingBookings,
        });
        this.lazyApp = import('firebase/app');
        this.lazyDatabase = import('firebase/database');

        Promise.all([this.lazyApp, this.lazyDatabase]).then(([firebase]) => {
          this.availabilityData = getFirebase(firebase).database().ref();
          this.setAvailability();
        });
      }
    }

    componentDidUpdate(prevProps, prevState) {
      const { currentSession } = this.state;
      if (typeof window !== 'undefined') {
        const {
          availabilitySession,
          restaurant,
          restaurantAvailabilityRef,
          selectedDate,
          searchFilterState,
        } = this.props;
        if (
          (availabilitySession !== prevProps.availabilitySession ||
            prevProps.restaurant.foreignId !== restaurant.foreignId ||
            currentSession !== prevState.currentSession ||
            selectedDate !== prevProps.selectedDate ||
            (searchFilterState &&
              searchFilterState.filters.includes('hasLastTable') &&
              !prevProps.searchFilterState.filters.includes('hasLastTable'))) &&
          this.availabilityData
        ) {
          this.setAvailability();
        }
        const { availability } = this.state;
        if (
          prevState.availability !== availability &&
          restaurantAvailabilityRef
        ) {
          const id = restaurant.foreignId || restaurant.id;
          const update = {};
          update[id] = availability;
          restaurantAvailabilityRef(update);
        }
      }
    }

    componentWillUnmount() {
      if (this.availabilityData) {
        this.availabilityData.off();
      }
      if (this.restaurantAvailability) {
        this.restaurantAvailability.off();
      }
    }

    setCurrentSession() {
      const { currentSession } = this.state;
      this.setState({
        currentSession: currentSession === 'dinner' ? 'dinner2' : 'dinner',
        forceDinnerSessionChange: true,
      });
    }

    getAvailabilityKey() {
      const { currentSession } = this.state;
      const { availabilitySession, showAllAvailability } = this.props;
      if (currentSession === 'dinner2') {
        return currentSession;
      }
      return !showAllAvailability ? availabilitySession : '';
    }

    setAvailability() {
      const { forceDinnerSessionChange, currentSession } = this.state;
      const {
        restaurant,
        availabilitySession,
        showAllAvailability,
        selectedDate,
        allowFiltering,
      } = this.props;
      const { upcomingBookings, shouldShowRestaurant } = this.state;
      let showRestaurant = shouldShowRestaurant;
      let newSession =
        currentSession === 'dinner2' ? currentSession : availabilitySession;
      // const hasLastTable = restaurant.availabilitySessions.includes('dinner2');
      const availabilityKey = this.getAvailabilityKey();
      const id = restaurant.foreignId || restaurant.id;
      this.restaurantAvailability = this.availabilityData.child(
        `availability/${id}`,
      );
      this.restaurantAvailability.on('value', (snapshot) => {
        const baseSnapShot = snapshot.val() || {};
        let availabilitySnapShot = baseSnapShot;

        if (!showAllAvailability) {
          availabilitySnapShot = availabilitySnapShot[availabilityKey];
        }

        // only display availability for live restaurants
        if (
          (availabilitySnapShot &&
            [
              'Live',
              'Ready To Go Live',
              'Pending Approval',
              'Pending',
            ].includes(restaurant.status)) ||
          (availabilitySnapShot &&
            process.env.GATSBY_SHOW_LOCKDOWN_AVAILABILITY)
        ) {
          // delete any sessions that don't exist in the restaurants available sessions
          if (showAllAvailability) {
            Object.keys(availabilitySnapShot).forEach((session) => {
              if (!restaurant.availabilitySessions.includes(session)) {
                delete availabilitySnapShot[session];
              }
            });
          }

          // filter out upcomingBookings for a particular user
          const availabilityToFilter = showAllAvailability
            ? availabilitySnapShot[availabilitySession]
            : availabilitySnapShot;
          if (availabilityToFilter) {
            Object.keys(availabilityToFilter).forEach((i) => {
              if (upcomingBookings.indexOf(availabilityToFilter[i].id) !== -1) {
                availabilityToFilter[i].available = false;
              }
            });
          }
          // date filtering of restaurants for lunch and breakfast
          if (
            selectedDate !== 'any' &&
            ['lunch', 'breakfast'].includes(availabilitySession) &&
            allowFiltering // don't show if all restaurant availability is requested ie restaurant page
          ) {
            const generalAvailability =
              baseSnapShot[availabilitySession][selectedDate];
            // if general availability
            showRestaurant = !!(
              generalAvailability && generalAvailability.available
            );
          }
          // date filtering of restaurants for dinner and dinner2
          if (
            selectedDate !== 'any' &&
            ['dinner', 'dinner2'].includes(availabilitySession) &&
            allowFiltering // don't show if all restaurant availability is requested ie restaurant page
          ) {
            const dinner2Availability = baseSnapShot?.dinner2?.[
              `${selectedDate}`
            ]
              ? baseSnapShot?.dinner2?.[`${selectedDate}`]
              : {};
            const dinnerAvailability = baseSnapShot?.dinner?.[`${selectedDate}`]
              ? baseSnapShot?.dinner?.[`${selectedDate}`]
              : {};
            // if current session is dinner or dinner and no availability, hide restaurant
            if (
              !dinnerAvailability.available &&
              !dinner2Availability.available
            ) {
              showRestaurant = false;
            }
            // if current session is dinner or dinner 2 and we have dinner 2 availability, default to dinner
            if (dinnerAvailability.available && !forceDinnerSessionChange) {
              showRestaurant = true;
              newSession = 'dinner';
            }
            if (
              !dinnerAvailability.available &&
              dinner2Availability.available &&
              !forceDinnerSessionChange
            ) {
              showRestaurant = true;
              newSession = 'dinner2';
            }
          }
          if (selectedDate === 'any') {
            showRestaurant = true;
          }
          return this.setState({
            availability: availabilitySnapShot || {},
            loading: false,
            shouldShowRestaurant: showRestaurant,
            currentSession: newSession,
          });
        }
        // mocks 7 days of closed availability
        availabilitySnapShot = {};
        range(0, 7).forEach((i) => {
          const today = moment();
          const date = today.add(i, 'days').format('YYYY-MM-DD');
          availabilitySnapShot[date] = {
            id: uuid(),
            date,
            open: true,
            availability: false,
            session: availabilitySession,
          };
        });
        if (showAllAvailability) {
          // mock the availability for the requested session (if restaurant layout)
          const sessionAvailability = {};
          sessionAvailability[availabilitySession] = {
            ...availabilitySnapShot,
          };
          return this.setState({
            availability: sessionAvailability,
            loading: false,
          });
        }
        return this.setState({
          availability: availabilitySnapShot,
          loading: false,
        });
      });
    }

    render() {
      const { allowFiltering } = this.props;
      const {
        availability,
        loading,
        currentSession,
        shouldShowRestaurant,
      } = this.state;

      if (allowFiltering && !shouldShowRestaurant) {
        return null;
      }
      return (
        <WrappedComponent
          isLoading={loading}
          availability={availability}
          setCurrentSession={this.setCurrentSession}
          currentSession={currentSession}
          {...this.props}
        />
      );
    }
  };

export default withFireBaseAvailability;
